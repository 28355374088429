import Footer from "../components/common/Footer";
import DefaultHeader from "../components/common/DefaultHeader";
import HeaderSidebar from "../components/common/HeaderSidebar";
import HeaderTop from "../components/common/HeaderTop";
import MobileMenu from "../components/common/MobileMenu";
import LoginSignupModal from "../components/common/login-signup";

import ListWithImage from "../components/pages/service/ListWithImage";
import ServiceBlock from "../components/pages/service/ServiceBlock";
//import ScheduleService from "@/app/components/pages/service/ScheduleService";
import ServiceHours from "../components/pages/service/ServiceHours";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

export const metadata = {
    title: "Service || Voiture - Automotive & Car Dealer NextJS Template",
};

const Service = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    const { t } = useTranslation();

    <Helmet>
        <title>Services || KAGA Motors - Automotive & Car Dealer in Japan</title>
        <meta name="description" content="Services at KAGA Motors - Automotive & Car Dealer in Japan." />
        <meta name="theme-color" content="#008f68" />
        <meta property="og:title" content={'Services || KAGA Motors - Automotive & Car Dealer'} />
        <meta property="og:description" content={'Services at KAGA Motors - Automotive & Car Dealer in Japan.'} />
        <meta property="og:image" content={'https://kagamotors.jp/assets/images/logo.png'} />
        <meta property="og:url" content={'https://kagamotors.jp/services'} />
        <meta property="og:type" content="website" />
    </Helmet>
    
    return (
        <div className="wrapper">
            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <HeaderSidebar t={t} />
            </div>
            {/* Sidebar Panel End */}

            {/* header top */}
            <HeaderTop t={t} />
            {/* End header top */}

            {/* Main Header Nav */}
            <DefaultHeader t={t} />
            {/* End Main Header Nav */}

            {/* Main Header Nav For Mobile */}
            <MobileMenu t={t} />
            {/* End Main Header Nav For Mobile */}

            {/* <!-- Inner Page Breadcrumb --> */}
            {/*  style2 bgc-f9 bt1 */}
            <section className="inner_page_breadcrumb style2 bgc-f9 bt1"> 
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            {/* style2 */}
                            <div className="breadcrumb_content style2">
                                <h2 className="breadcrumb_title">{ t('services.our-services-include')}</h2>
                                <p className="subtitle">{ t('header.services') }</p>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="#">{ t('header.home') }</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                    <a href="#">{ t('header.services') }</a> 
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Inner Page Breadcrumb --> */}

            {/* Service Section Area */}
            <section className="our-service bgc-f9 pb90 pt0">
                <div className="container">
                    <ListWithImage t={t} />
                </div>
            </section>
            {/* End Service Section Area */}

            {/* Service Section Area */}
            <section className="our-service">
                <div className="container">
                    <ServiceBlock t={t} />
                </div>
            </section>
            {/* Service Section Area */}

            {/* Service Forms Section Area */}
            {/* <section className="service-forms bgc-f9">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-xl-8">
                            <div className="service_form mb30-sm">
                                <h5 className="title">Schedule Service</h5>
                                <ScheduleService />
                            </div>
                        </div>

                        <div className="col-md-5 col-xl-4">
                            <div className="opening_hour_widgets">
                                <div className="wrapper">
                                    <h4 className="title">Opening hours</h4>
                                    <ServiceHours />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* End Service Forms Section Area */}

            {/* Our Footer */}
            <Footer t={t} />
            {/* End Our Footer */}

            {/* Modal */}
            <div
                className="sign_up_modal modal fade"
                id="logInModal"
                data-backdrop="static"
                data-keyboard="false"
                tabIndex={-1}
                aria-hidden="true"
            >
                <LoginSignupModal />
            </div>
            {/* End Modal */}
        </div>
        // End wrapper
    );
};

export default Service;
