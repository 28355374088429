const Address = ({t}) => {
	const addressData = {
		title: t('contact-us.contact-details'),
		address: (
			<>
				{ t('contact-us.contact-address-line-1') }<br/> 
				{ t('contact-us.contact-address-line-2') }
				<br />
				{ t('contact-us.contact-address-line-3') }
				<br />
				{ t('contact-us.contact-address-line-4') }
			</>
		),
		subtitle: "0280-33-6090",
		email: "info@kagamotors.jp",
		socialIcons: [
			{ iconClass: "fab fa-facebook-f", link: "https://facebook.com/kagamotors" },
			{ iconClass: "fab fa-whatsapp", link: "https://wa.me/818046319655" },
			{ iconClass: "fab fa-facebook-messenger", link: "https://m.me/kagamotors" },
			{ iconClass: "fab fa-line", link: "https://line.me/ti/p/TlypxaPZEX" },
		],
	};

	return (
		<div className="contact_icon_box mb50">
			<div className="details">
				<h3 className="title">{addressData.title}</h3>
				<p>{addressData.address}</p>
				<h4 className="subtitle">
					<a href={`tel:${addressData.subtitle}`}>{addressData.subtitle}</a>
				</h4>
				<p>
					<a href={`mailto:${addressData.email}`}>{addressData.email}</a>
				</p>
				<div className="footer_social_widget">
					<ul className="mb0">
						{addressData.socialIcons.map((icon, index) => (
							<li key={index} className="list-inline-item">
								<a href={icon.link} target="_blank">
									<i className={icon.iconClass} />
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Address;
