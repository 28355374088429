import React from "react";
//import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/bundle';

import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";

const Hero = () => {
	const carSlides = [
		{
			image: "../../assets/images/home/front1.jpg",
			title: "Audi A8 L 55",
			price: "$746",
		},
		{
			image: "../../assets/images/home/front2.jpg",
			title: "Audi A8 L 55",
			price: "$865",
		},
		{
			image: "../../assets/images/home/front3.jpg",
			title: "BMW M8 Gran Coupe",
			price: "$976",
		},
	];

	return (
		<div className="main-banner-wrapper home2_main_slider mb30-md">
			<div className="banner-style-one">
				<Swiper
					spaceBetween={0}
					slidesPerView={1}
					loop={true}
					modules={[Autoplay, Navigation]}
					navigation={{
						nextEl: ".right-btn",
						prevEl: ".left-btn",
					}}
					autoplay={{
						delay: 3500,
						disableOnInteraction: false,
					}}
				>
					{carSlides.map((slide, index) => (
						<SwiperSlide key={index}>
							<div
								className="slide slide_image"
								style={{
									backgroundImage: `url(${slide.image})`,
									height: "750px",
									// objectFit: "scale-down",
								}}
							>
								{/* <div className="container">
									<div className="row home-content-home2-style">
										<div className="col-lg-12 p0">
											<h2 className="banner-title">
												<small>2023</small> <br /> {slide.title}
											</h2>
											<h3 className="banner_top_title text-thm d-flex align-items-baseline">
												<span className="aminated-object1">
													<img
														width={110}
														height={14}
														style={{
															objectFit: "contain",
														}}
														priority
														className="objects"
														src="../../assets/images/home/title-bottom-border.svg"
														alt="border image"
													/>
												</span>
												<span>{slide.price}</span>{" "}
												<small className="text-white d-inline-block ms-2">
													/ Month
												</small>
											</h3>
											<NavLink
												to="/listing-single-v1"
												className="btn banner-btn"
											>
												Learn More
											</NavLink>
										</div>
									</div>
								</div> */}
							</div>
						</SwiperSlide>
					))}

					{/* start navigation  */}
					<div className="carousel-btn-block banner-carousel-btn">
						<span className="carousel-btn left-btn">
							<i className="flaticon-left-arrow left" />
						</span>
						<span className="carousel-btn right-btn">
							<i className="flaticon-right-arrow right" />
						</span>
					</div>
					{/* End navigation */}
				</Swiper>
			</div>
		</div>
	);
};

export default Hero;
