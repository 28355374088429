
import { useState, useEffect, useMemo } from "react";
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
//import listingCar from "@/data/listingCar";
import axios from 'axios';
import PriceDisplay from '../../../common/priceDisplay';
import { NumericFormat } from 'react-number-format';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
    //console.log('Search: ' + search);
    //return useMemo(() => new URLSearchParams(search), [search]);
	return new URLSearchParams(search);
}

const CarItems = ({ pagination, setter, filter }) => {
	//const searchParams = useSearchParams()
	//const navigate = useNavigate();
    //const location = useLocation();
	//let query = useQuery();

	const [listing, setListing] = useState([]);

	const [searchQueries, setSearchQueries] = useState([]);

	const [exRate, setExRate] = useState(async () => {
		try {
			const ex_rate = await axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)    
			
			console.log('USD : ' + ex_rate.data.rates.USD);
			setExRate(ex_rate.data.rates.USD);
			//return ex_rate.data.rates.USD;
		} catch (error) {
			console.error(error);
		}
	});

	function fetchData() {
		//Listing List
		let temp_ = (pagination.page_) ? pagination.page_ : 1;
		axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/listing?page=${temp_}`, {
			params: filter //?.map((item) => ({[item.name]: item.value }) )
		})
		.then((res) => {
			//console.log(res.data);
			setListing(res.data.data);
			setter.setCurrPage(parseInt(res.data.curr_page));
            setter.setHasNext(res.data.hasNext);
            setter.setHasPrev(res.data.hasPrev);
			setter.setCount(res.data.count);
			
		})
		.catch((err) => {
			console.error(err);
		})
	};

	useEffect(() => {
		console.log('Fetch Vehicles : ' + filter.keyword);
		fetchData();
	}, [])

	useEffect(() => {
		console.log('Fetch Vehicles 2 : ' + filter.keyword);
        fetchData();
    },
    [searchQueries, filter, pagination.page_])


	let thumbnail_ = "https://www.tc-v.com/cdn/trade/img06/cars/1689032/30564053/01.jpg";

	return (
		<>
			{listing?.map((listing, index) => {
				let date_ = new Date(listing.created_at);
				//console.log('Date ' + date_);
				let today_ = new Date();
				//console.log('Today ' + today_);

				let diff_ = Math.floor((today_ - date_) / (1000 * 60 * 60 * 24));

				//console.log('Diff ' + diff_);

				return (
					<div className="col-xs-6 col-sm-6 col-lg-4 col-xl-3" key={listing.id}>
						<div className="car-listing">
							<NavLink to={`/detail/${listing.id}`}>
							<div className="thumb">
								{
									listing.availability == 2 ? (
										<>
											<div className="tag red">Sold</div>
										</>
									) : 
									listing.tag ? (
										<>
											<div className="tag">{listing.tag}</div>
										</>
									) : diff_ < 10 ? (
										<>
											<div className="tag blue">New</div>
										</>
									) : <div className="tag green">In-Stock</div>
								}
	
								<img
									width={284}
									height={183}
									style={{
										width: "100%",
										height: "100%",
										objectFit: "cover",
									}}
									priority
									src={(listing.tumbnail) ? listing.tumbnail : (listing.tcv_images) ? JSON.parse(listing.tcv_images)[0] : (listing.gallery) ? JSON.parse(listing.gallery)[0] : thumbnail_ } //{thumbnail_}
									alt={listing.title}
								/>
								<div className="thmb_cntnt2">
									<ul className="mb0">
										<li className="list-inline-item">
											<a className="text-white" href="#">
												<span className="flaticon-photo-camera mr3" />{" "}
												{listing.photosCount}
											</a>
										</li>
										<li className="list-inline-item">
											<a className="text-white" href="#">
												<span className="flaticon-play-button mr3" />{" "}
												{listing.videosCount}
											</a>
										</li>
									</ul>
								</div>
								<div className="thmb_cntnt3">
									<ul className="mb0">
										<li className="list-inline-item">
											<a href="#">
												<span className="flaticon-shuffle-arrows" />
											</a>
										</li>
										<li className="list-inline-item">
											<a href="#">
												<span className="flaticon-heart" />
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="details">
								<div className="wrapper">
									<h5 className="price">{ listing.availability == 2 && '-' ||(parseInt(listing.price) == 0) ? 'Inquire for Price' : <PriceDisplay price={listing.price} rate={exRate} /> }</h5>
									{/* <h5 className="price"><img src="./assets/images/item/yen.png" alt="¥" height={13} />&nbsp; <PriceDisplay price={listing.price} rate={exRate} /> { Number(listing.price) }</h5> */}
									<h6 className="title">
										<NavLink to={`/detail/${listing.id}`}>{ listing.ref_no + ' | ' } {((listing.title).length > 30) ? (listing.title).slice(0, 27) + '...' : listing.title}</NavLink> 
									</h6>
									<div className="listign_review">
										<ul className="mb0">
											<li className="list-inline-item" style={{ letterSpacing: '1.2px' }}>
												{ listing.m_year }<b style={{ letterSpacing: '2px', fontWeight: '900' }}>/</b>{ listing.m_month }
											</li>
											<li className="list-inline-item">
												&nbsp;-&nbsp;{ listing.model_code }
											</li>
											{/* {[...Array(5)].map((_, index) => (
												<li key={index} className="list-inline-item">
													<a href="#">
														<i className="fa fa-star" />
													</a>
												</li>
											))}
											<li className="list-inline-item">
												<a href="#">{listing.rating}</a>
											</li>
											<li className="list-inline-item">
												({listing.reviewsCount} reviews)
											</li> */}
										</ul>
									</div>
								</div>
								{/* End wrapper */}
	
								<div className="listing_footer">
									<ul className="mb0">
										<li className="list-inline-item">
											<span className="flaticon-road-perspective me-2" />
											{/* {listing.mileage } */}
											<NumericFormat style={{ fontSize: '12px' }} type="text" displayType="text"  value={listing.mileage} thousandsGroupStyle="thousand" thousandSeparator="," />
										</li>
										<li className="list-inline-item">
											<span className="flaticon-gas-station me-2" />
											{((listing.FuelType?.name).length > 9) ? (listing.FuelType?.name).slice(0, 9) + '...' : listing.FuelType?.name }
										</li>
										<li className="list-inline-item">
											<span className="flaticon-gear me-2" />
											{ listing.TransmissionType?.name }
											{/* { ((listing.TransmissionType?.name).length > 7) ? (listing.TransmissionType?.name).slice(0, 7) + '...' : listing.TransmissionType?.name } */}
										</li>
									</ul>
								</div>
							</div>
							</NavLink>
						</div>
					</div>
				)
			})}
			{/* ------ */}
		</>
	);
};


export default CarItems;
