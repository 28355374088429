const HeaderSidebar = ({t}) => {

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };
    
    return (
        <div className="offcanvas-body">
            <div className="siderbar_left_home pt20 active">
                <button
                    className="sidebar_switch sidebar_close_btn float-end"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                >
                    X
                </button>
                {/* End .cose button */}

                {/* <div className="footer_contact_widget mt100">
                    <h3 className="title">Quick contact info</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean massa.
                        Cum sociis Theme natoque penatibus et magnis dis
                        parturient montes, nascetur.
                    </p>
                </div> */}
                {/* End widget */}

                <div className="footer_contact_widget">
                    <h5 className="title">{ t('side-bar.side-contact-no') }</h5>
                    <div className="footer_phone">0280-33-6090</div>
                    <div className="footer_phone">080-4631-9655</div>
                    {/* <p> info@kagamotors.com / export@kagamotors.com</p> */}
                </div>
                {/* End widget */}

                <div className="footer_contact_widget">
                    <h5 className="title">{ t('side-bar.side-email') }</h5>
                    <div className="footer_phone"><a href="mailto:info@kagamotors.jp" style={{ color: "white" }} >info@kagamotors.jp</a></div>
                    <div className="footer_phone"><a href="mailto:export@kagamotors.jp" style={{ color: "white" }} >export@kagamotors.jp</a></div>
                </div>
                {/* End widget */}

                {/* <span className={contact.icon} /> */}

                <div className="footer_about_widget">
                    <h5 className="title">{ t('side-bar.side-sales-office-nogi') }</h5>
                    <p>
                        { t('side-bar.side-address-line-1') }
                        <br></br>
                        { t('side-bar.side-address-line-2') }
                    </p>
                </div>
                {/* End widget */}

                <div className="footer_contact_widget">
                    <h5 className="title">{ t('side-bar.side-opening-hours') }</h5>
                    <p>
                    { t('side-bar.side-monday') } Monday - { t('side-bar.side-friday') } Friday: 08.30 - 17:00
                    <br></br>
                    { t('side-bar.side-saturday') } : 09.00 - 17:00
                    <br></br>
                    { t('side-bar.side-sunday') } : { t('side-bar.side-closed') }
                    </p>
                </div>
                {/* End widget */}

                <div className="offer_btns">
                    <div className="text-end">
                        <button type="submit" className="btn btn-block ofr_btn1 btn-outline-primary mb0" onClick={(e) => openInNewTab('https://facebook.com/kagamotors')}>
                            <span className="fab fa-facebook-f mr10 text-white" />
                            Facebook
                        </button>
                    </div>
                </div>
                <br></br>
                <div className="offer_btns">
                    <div className="text-end">
                        <button type="submit" className="btn btn-block ofr_btn1 btn-outline-danger mb0" onClick={(e) => openInNewTab('https://youtube.com/@kagamotors')}>
                            <span className="fab fa-youtube mr10 text-white" />
                            Youtube
                        </button>
                    </div>
                </div>
                <br></br>
                <div className="offer_btns">
                    <div className="text-end">
                        <button type="submit" className="btn btn-block ofr_btn1 btn-outline-success mb0" onClick={(e) => openInNewTab('https://line.me/ti/p/TlypxaPZEX')}>
                            <span className="fab fa-line mr10 text-white" />
                            Line
                        </button>
                    </div>
                </div>
                <br></br>
                <div className="offer_btns">
                    <div className="text-end">
                        <button type="submit" className="btn btn-block ofr_btn1 btn-whatsapp mb0" onClick={(e) => openInNewTab('https://wa.me/818046319655')}>
                            <span className="flaticon-whatsapp mr10 text-white" />
                            WhatsApp
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default HeaderSidebar;
