import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../../services/crudService'

import Pagination from "../Pagination";
import ListingContent from "./ListingContent";

const FilterDiv = (props) => {
    const [orderId_, setOrderId_] = useState('');
    const [orderDate_, setOrderDate_] = useState('');
    const [orderDateTo_, setOrderDateTo_] = useState('');
    const [type_, setType_] = useState('');
    const [status_, setStatus_] = useState('');
    const [supplier_, setSupplier_] = useState('');
    const [reference_, setReference_] = useState('');
    const [vendorList, setVendorList] = useState([]);

    let navigate = props.navigation;
    let locationHook = props.locationHook;

    const handleFilter = () => {
        let data = { orderId: orderId_, orderDate: orderDate_, orderDateTo: orderDateTo_, type: type_, status: status_, supplier: supplier_, reference: reference_ }
        props.searchQuery(data);
    }

    const handleExcel = async (e) => {

        try {
            const response = await CrudService.GetFileData(navigate, locationHook, 'sale/export-excel', 
            {
                order_id: orderId_,
                order_date: orderDate_,
                order_date_to: orderDateTo_,
                type: type_,
                status: status_
            });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sales_report.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        }
    }

    const handleClear = () => {
        setSupplier_('');
        setReference_('');
        handleFilter();
    }

    useEffect(() => {
        //Supplier List
        CrudService.GetData(navigate, locationHook, 'vendor/basic')
        .then((res) => {
            setVendorList(res.data);
        })
        .catch((err) => console.error(err))

    }, [])

    return (
		<>
			<div className="col-sm-12 col-lg-2">
				<div className="advance_search_style">
					<input
						className="form-control form_control"
						name="keyword"
						type="text"
						placeholder={"t('hero-filter.search-model-and-reference')"}
						value={''}
						onChange={e => null}
						style={{ width: "100%"}}
					/>
				</div>
			</div>
			<div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick"  name="bodytype" value={'searchQueries.bodytype'} onChange={e => null}>
						<option value={''} key={0}>{"t('hero-filter.select-category')"}</option>
						{/* {data?.categories?.map((value, valueIndex) => (
							<option key={valueIndex} value={value.id} >{value.name}</option>
						))} */}
					</select>
				</div>
			</div>
			<div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick"  name="bodytype2" value={'searchQueries.bodytype2'} onChange={e => null}>
						<option value={''} key={0}>{"t('stock.select-subcategory')"}</option>
							{/* {subCatagoriesList?.map((value, valueIndex) => (
								<option key={valueIndex} value={value.id} >{value.name}</option>
							))} */}
					</select>
				</div>
			</div>
			<div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick" name="make" value={'searchQueries.make'} onChange={e => null}>
						<option value={''} key={0}>{"t('hero-filter.select-make')"}</option>
						{/* {data?.makes.map((value, valueIndex) => (
							<option key={valueIndex} value={value.id}>{value.name}</option>
						))} */}
					</select>
				</div>
			</div>
		</>


        // <section class="content">
        //     <div className="card">
        //         <div className="card-body">
                    // <div className="row">
                    //     <div className='col-md-2'>
                    //         <div className="form-group">
                    //             <input type="text" className="form-control form-control-sm" id="orderId" placeholder="Order Id" value={orderId_} onChange={(e) => setOrderId_(e.target.value)}/>
                    //         </div>
                    //     </div>
                    //     <div className='col-md-2'>
                    //         <div className="form-group">
                    //             <input type="text" className="form-control form-control-sm" id="reference" placeholder="Reference" value={reference_} onChange={(e) => setReference_(e.target.value)}/>
                    //         </div>
                    //     </div>
                    //     <div className='col-md-2'>
                    //         <div className="form-group">
                    //             <input type="date" className="form-control form-control-sm" id="orderDateFrm" placeholder="Date From" value={orderDate_} onChange={(e) => setOrderDate_(e.target.value)}/>
                    //         </div>
                    //     </div>
                    //     <div className='col-md-2'>
                    //         <div className="form-group">
                    //             <input type="date" className="form-control form-control-sm" id="orderDateTo" placeholder="Date To" value={orderDateTo_} onChange={(e) => setOrderDateTo_(e.target.value)}/>
                    //         </div>
                    //     </div>
                    //     {/* Supplier */}
                    //     {/* <div className='col-md-2'>
                    //         <div className="form-group">
                    //             <Select styles={{control: customControlStyles}} options={vendorList.map((item, index) => ({ value: item.id, label: item.name }))} onChange={(e) => { console.log('e :' + e.value); setSupplier_(e.value);}} placeholder="Supplier"/>
                    //         </div>
                    //     </div> */}
                    //     <div className='col-md-2'>
                    //         <div className="form-group">
                    //             <select class="form-control form-control-sm" id="type" value={type_} onChange={(e) => { setType_(e.target.value);}}>
                    //                 <option value=''>-- Type --</option>
                    //                 <option value='1'>Domestic</option>
                    //                 <option value='2'>Export</option>
                    //             </select>
                    //         </div>
                    //     </div>
                    //     <div className='col-md-2'>
                    //         <div className="form-group">
                    //             <select class="form-control form-control-sm" id="status" value={status_} onChange={(e) => { setStatus_(e.target.value);}}>
                    //                 <option value=''>-- Status --</option>
                    //                 <option value='1'>Pending</option>
                    //                 <option value='2'>Completed</option>
                    //             </select>
                    //         </div>
                    //     </div>
                    //     <div className='col-md-1'>
                    //         <div className="form-group">
                    //             <button className='btn btn-info btn-sm btn-block' onClick={handleFilter}>Filter</button>
                    //         </div>
                    //     </div>
                    //     <div className='col-md-1'>
                    //         <div className="form-group">
                    //             <button className='btn btn-info btn-sm btn-block' onClick={handleExcel}>Export</button>
                    //         </div>
                    //     </div>
                    //     <div className='col-md-1'>
                    //         <div className="form-group">
                    //             <button className='btn btn-info btn-sm btn-block' onClick={handleClear}>Clear</button>
                    //         </div>
                    //     </div>
                    // </div>
        //         </div>
        //     </div>
        // </section>
    )      
}

const ListingTabContent = () => {

	const navigate = useNavigate();
    const location = useLocation();
    const [orderList, setOrderList] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [count_, setCount_] = useState(0);
    const handleFilter = () => setShowFilter(!showFilter);

    const [searchQuery, setSearchQuery] = useState([]);
    
    const fetchData = () => {
        console.log('fetchData page:' + page_)
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/category?page=${0}&pageSize=${10}`)
        CrudService.GetDataWithParam(navigate, location, 'order/active', 
        { 
            page: page_,
            order_id: searchQuery.orderId, 
            order_date: searchQuery.orderDate, 
            type: searchQuery.type, 
            status: searchQuery.status
        })
        .then((res) => {
            console.log(res.data);
            setOrderList(res.data?.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setCount_(res.data.count);
        }).catch((error) => {
            console.error(error);
        })
    }

	//Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }
    //---------------------------------------------

    useEffect(() => {
        fetchData();
    }, []);

	useEffect(() => {
        fetchData();
    }, [page_]);

	return (
		<>
			<div className="row">
				{/* <FilterDiv avigate={navigate} locationHook={location} searchQuery={setSearchQuery} /> */}
				<div className="col-lg-12">
					{/* <!-- Nav tabs --> */}
					{/* <div className="nav nav-tabs justify-content-start" role="tablist">
						<button
							className="nav-link active"
							id="nav-home-tab"
							data-bs-toggle="tab"
							data-bs-target="#nav-home"
							role="tab"
							aria-controls="nav-home"
							aria-selected="true"
						>
							All Status
						</button>
						<button
							className="nav-link"
							id="nav-shopping-tab"
							data-bs-toggle="tab"
							data-bs-target="#nav-shopping"
							role="tab"
							aria-controls="nav-shopping"
							aria-selected="false"
						>
							New Cars
						</button>
						<button
							className="nav-link"
							id="nav-hotels-tab"
							data-bs-toggle="tab"
							data-bs-target="#nav-hotels"
							role="tab"
							aria-controls="nav-hotels"
							aria-selected="false"
						>
							Used Cars
						</button>
					</div> */}
				</div>
				{/* <!-- Tab panes --> */}
				
				{/* mt50 */}
				<div className="col-lg-12">
					<div className="tab-content row" id="nav-tabContent">
						<div
							className="tab-pane fade show active"
							id="nav-home"
							role="tabpanel"
							aria-labelledby="nav-home-tab"
						>							
							<ListingContent orderList={orderList} />
						</div>
						{/* End tab-content */}

						{/* <div
							className="tab-pane fade"
							id="nav-shopping"
							role="tabpanel"
							aria-labelledby="nav-shopping-tab"
						>
							<ListingContent orderList={orderList} />
						</div> */}
						{/* End tab-content */}

						{/* <div
							className="tab-pane fade"
							id="nav-hotels"
							role="tabpanel"
							aria-labelledby="nav-hotels-tab"
						>
							<ListingContent orderList={orderList} />
						</div> */}
					</div>
					{/* End tab-content */}

					<div className="mbp_pagination mt10">
						<Pagination pagination={{ currPage: currPage, page_: page_, hasNext: hasNext, hasPrev: hasPrev, count: count_ }} 
                                    setter={{ setCurrPage: setCurrPage, setPage_: setPage_, setHasNext: setHasNext, setHasPrev: setHasPrev, setCount: setCount_ }}/>
					</div>
					{/* Pagination */}
				</div>
			</div>
		</>
	);
};

export default ListingTabContent;
