import MainMenu from "../common/MainMenu";
import { NavLink } from "react-router-dom";
import { useCurrency } from '../../context/currencyContext';
import i18next from 'i18next';

const Header = ({t}) => {
	const { currency, setCurrency } = useCurrency();
	var curr_lang = '';

	function handleLanguage(lang) {
        i18next.changeLanguage(lang)
        
        curr_lang = i18next.language;
        console.log('curr_lang :' + curr_lang);
    }

	const handleCurrencyChange = (val) => {
		console.log('Curr : ' + val);
		setCurrency(val);
	};

	return (
		<header className="header-nav menu_style_home_one home2_style main-menu">
			{/* Ace Responsive Menu */}
			<nav>
				{/* -fluid */}
				<div className="container-fluid posr">
					{/* Menu Toggle btn*/}
					<div className="menu-toggle">
						<button type="button" id="menu-btn">
							<span className="icon-bar" />
							<span className="icon-bar" />
							<span className="icon-bar" />
						</button>
					</div>
					<NavLink to="/" className="navbar_brand float-start dn-md">
						<img
							width={300}
							height={45}
							className="logo1 img-fluid"
							src="../../assets/images/logo.png"
							alt="Kagamotors Logo"
						/>
						<img
							width={350}
							height={45}
							className="logo2 img-fluid"
							src="../../assets/images/favicon.png"
							alt="Kagamotors Logo"
						/>
					</NavLink>
					{/* Responsive Menu Structure*/}

					{/* <ul
						id="respMenu"
						className="ace-responsive-menu text-end"
						data-menu-style="horizontal"
						style={{ maxWidth: '20%' }}
					>
						<li className="add_listing">
							<span role='button'><img src="../../assets/images/header/en.png" width={'34px'}/> </span>
							<span role='button'> <img src="../../assets/images/header/jp.png" width={'28px'}/> </span>
						</li>
					</ul> */}

					<ul
						id="respMenu"
						className="ace-responsive-menu text-end"
						data-menu-style="horizontal"
					>
						<MainMenu t={t} />
						
						{/* <li className="add_listing">
							<NavLink to="/add-listings">USD</NavLink>
						</li> */}
						<li className="add_listing">
							<span role='button' onClick={() => handleLanguage('en')}><img src="../../assets/images/header/en.png" width={'34px'}/> </span>
							<span role='button' onClick={() => handleLanguage('jp')}> <img src="../../assets/images/header/jp.png" width={'30px'}/> </span>
						</li>
						<li className="dropitem" key={1}>
							{/* ------- */}
							{/* <li class="dropitem"> */}
								<a class="" href="#">
									<span class="title"><img src="./assets/images/header/currency.png" width={25} /></span>
									<span class="arrow"></span>
								</a>
								<ul class="sub-menu">
									<li class="dropitem">
										<a class="" href="#" role="button" onClick={() => handleCurrencyChange('USD')}><img src="./assets/images/header/usd.png" width={32} /> &nbsp; <b>USD</b></a>
										<a class="" href="#" role="button" onClick={() => handleCurrencyChange('JPY')}><img src="./assets/images/header/jpy.png" width={32} /> &nbsp; <b>JPY</b></a>
									</li>
								</ul>
							{/* </li> */}
							{/* ------- */}
						</li>
						<li
							className="sidebar_panel"
							data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasRight"
							aria-controls="offcanvasRight"
						>
							<a className="sidebar_switch pt0" role="button">
								<span />
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</header>
	);
};

export default Header;
