//import Image from "next/image";

const ServiceBlock = ({t}) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6 col-xl-5">
                    <div className="service_thumb mb30-sm">
                        <img
                            //width={526}
                            height={354}
                            priority
                            layout="responsive"
                            src="./assets/images/service/1.jpg"
                            alt="1.jpg"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-xl-5 offset-xl-1">
                    <div className="service_include2 mt0-md">
                        <h3 className="title">{t('services.towtruck-service')}</h3>
                        <p className="para">
                            {t('services.towtruck-service-desc')} 
                            {/* We do Tow Truck service for Heavy Truck and Bus in Kanto Region. Our prices are quite comprehensive, starting from JPY 50 000 for Heavy Truck and JPY 60 000 for heavy bus. Please feel free to contact us for any inquiries. */}
                        </p>
                    </div>
                </div>
            </div>
            {/* End .row */}

            <div className="row mt120 mt50-sm">
                <div className="col-md-6 col-xl-5">
                    <div className="service_include2 mt0-md mb30-sm">
                        <h3 className="title">{t('services.vehicle-dismantling')}</h3>
                        <p className="para">
                            {t('services.vehicle-dismantling-desc')}
                            {/* We have experienced staff in dismantling of all types of vehicles dismantling. Complete Knock Down (CKD) for reassembly, Half Cut, Nose Cut , Cabin Cut and etc. */}
                        </p>
                    </div>
                </div>
                <div className="col-md-6 col-xl-5 offset-xl-1">
                    <div className="service_thumb">
                        <img
                            //width={526}
                            height={354}
                            priority
                            layout="responsive"
                            src="./assets/images/service/2.jpg"
                            alt="2.jpg"
                        />
                    </div>
                </div>
            </div>
            {/* End .row */}

            <div className="row mt120 mt50-sm">
                <div className="col-md-6 col-xl-5">
                    <div className="service_thumb mb30-sm">
                        <img
                            //width={526}
                            height={354}
                            priority
                            layout="responsive"
                            src="./assets/images/service/3.jpg"
                            alt="3.jpg"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-xl-5 offset-xl-1">
                    <div className="service_include2 mt0-md">
                        <h3 className="title">{t('services.container-vanning')}</h3>
                        <p className="para">
                            {t('services.container-vanning-desc')}
                            {/* Container vanning requires the utmost expertise to enable balanced loading in a limited space. KAGA MOTORS has loading specialists who examine the size and features of vehicles so that the vehicles are securely restrained in the best possible positions. */}
                        </p>
                    </div>
                </div>
            </div>
            {/* End .row */}

            <div className="row mt120 mt50-sm">
                <div className="col-md-6 col-xl-5">
                    <div className="service_include2 mt0-md mb30-sm">
                        <h3 className="title">{t('services.shipping-services')}</h3>
                        <p className="para">
                            {t('services.shipping-services-desc')}
                            {/* KAGA MOTORS provide shipping service by trucking from auctions, yard storage, container packing, pre-export inspections, workshop repairs, freight forwarding and documentation. */}
                        </p>
                    </div>
                </div>
                <div className="col-md-6 col-xl-5 offset-xl-1">
                    <div className="service_thumb">
                        <img
                            //width={526}
                            height={354}
                            priority
                            layout="responsive"
                            src="./assets/images/service/4.jpg"
                            alt="4.jpg"
                        />
                    </div>
                </div>
            </div>

            <div className="row mt120 mt50-sm">
                <div className="col-md-6 col-xl-5">
                    <div className="service_thumb mb30-sm">
                        <img
                            //width={526}
                            height={354}
                            priority
                            layout="responsive"
                            src="./assets/images/service/5.jpg"
                            alt="5.jpg"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-xl-5 offset-xl-1">
                    <div className="service_include2 mt0-md">
                        <h3 className="title">{t('services.vehicle-inspection-service')}</h3>
                        <p className="para">
                            {t('services.vehicle-inspection-service-desc')}
                            {/* KAGA MOTORS provide comprehensive price evaluation. We have expertise in used Heavy Truck & Bus.  */}
                        </p>
                    </div>
                </div>
            </div>
            {/* End .row */}
        </>
    );
};

export default ServiceBlock;
