import React, { useState, useEffect } from "react";
import axios from 'axios';

const AdvanceMainFilter = ({ handleOutFilter, t }) => {
	const filterOptions = [
		// {
		// 	name: "year",
		// 	label: t('stock.year'), //"Year",
		// 	options: ["1967", "1990", "2000", "2001", "2002", "2003", "2004", "2005", "2010", "2015", "2016", "2017", "2018", "2020", "2021", "2022", "2023"],
		// 	type: "select",
		// },
		{
			name: "year",
			label: t('stock.year'), //"Year",
			//options: ["1967", "1990", "2000", "2001", "2002", "2003", "2004", "2005", "2010", "2015", "2016", "2017", "2018", "2020", "2021", "2022", "2023"],
			placeholder: t('stock.enter-year'),
			type: "input",
			inputType: "number",
		},
		// {
		// 	label: "Fuel Type",
		// 	options: ["Diesel", "Electric", "Hybrid", "Petrol"],
		// 	type: "select",
		// },
		// {
		// 	label: "Transmission",
		// 	options: ["Autometic", "Manual", "Semi-Autometic"],
		// 	type: "select",
		// },
		{
			name: "steering",
			label: t('stock.steering'), //"Steering",
			options: ['Left', 'Right', 'Center', 'Unspecified'],
			type: "select",
		},
		{
			name: "doors",
			label: t('stock.doors'), //"Doors",
			options: ["1", "2", "3", "4", "5"],
			type: "select",
		},
		{
			name: "seats",
			label: t('stock.seats'), //"Seats",
			options: ["1", "2", "3", "4", "7", "9", "24", "54"],
			type: "select",
		},
		// {
		// 	name: "i-color",
		// 	label: "Interior Color",
		// 	options: ["Black", "Beige", "Brown", "Red"],
		// 	type: "select",
		// },
		// {
		// 	name: "e-color",
		// 	label: "Exterior Color",
		// 	options: ["Black", "Beige", "Brown", "Red"],
		// 	type: "select",
		// },
		// {
		// 	name: "year",
		// 	label: "Cylinders",
		// 	options: ["4", "6", "8"],
		// 	type: "select",
		// },
		// {
		// 	name: "status",
		// 	label: "Listing Status",
		// 	options: ["Active", "Pending", "Disable"],
		// 	type: "select",
		// },
		// {
		// 	name: "min-milleage",
		// 	label: "Min. Mileage",
		// 	placeholder: "Min. Mileage",
		// 	type: "input",
		// 	inputType: "number",
		// },
		// {
		// 	name: "max-milleage",
		// 	label: "Max. Mileage",
		// 	placeholder: "Max. Mileage",
		// 	type: "input",
		// 	inputType: "number",
		// },
		// {
		// 	name: "vin",
		// 	label: "VIN number",
		// 	placeholder: "VIN number",
		// 	type: "input",
		// 	inputType: "number",
		// },
	];
	
	const [searchQueries, setSearchQueries] = useState([]);
	const [data, setData] = useState(() => 
		{
			console.log('AdvanceMainFilter - fetchData');
			axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/misc/searchdata`)
			.then((res) => {
				setData(res.data.data);
				return res.data;
			})
			.catch((err) => {
				console.error(err);
				return [];
			})
		}
	);

	const handleInputChange = (event) => {
		event.preventDefault();
        const { name, value } = event.target;

		//setSearchQueries([...searchQueries, {name: name, value: value}]);
		handleOutFilter( name, value );

        console.log('Triggered for:' + name);
		console.log('Triggered val:' + value);
    };

	return (
		<>	
			<div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick" name="fueltype" onChange={e => handleInputChange(e) }>
						<option value={''} key={0}>{t('stock.fuel-type')}</option>
						{data?.fuel_types?.map((value, valueIndex) => (
							<option key={valueIndex} value={value.id}>{value.name}</option>
						))}
					</select>
				</div>
			</div>
			<div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick" name="transmission" onChange={e => handleInputChange(e) }>
						<option value={''} key={0}>{t('stock.transmission')}</option>
						{data?.transmissions?.map((value, valueIndex) => (
							<option key={valueIndex} value={value.id}>{value.name}</option>
						))}
					</select>
				</div>
			</div>

			{filterOptions.map((option, index) => (
				<div className="col-12 col-sm-4 col-lg-2" key={index}>
					<div className="advance_search_style">
						{option.type === "select" ? (
							<select className="form-select show-tick" name={option.name} onChange={e => handleInputChange(e) }>
								<option value={''} key={0}>{option.label}</option>
								{option.options.map((opt, i) => (
									<option key={i} value={opt}>{opt}</option>
								))}
							</select>
						) : (
							<input
								className="form-control form_control"
								name={option.name}
								type={option.inputType}
								placeholder={option.placeholder}
								onChange={e => handleInputChange(e)}
							/>
						)}
					</div>
				</div>
			))}
		</>
	);
};

export default AdvanceMainFilter;
