import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import axios from 'axios';
//import "swiper/swiper-bundle.css";
import 'swiper/css';
import { NavLink, useLocation } from 'react-router-dom'
import PriceDisplay from '../../common/priceDisplay';
//import listingCar from "@/data/listingCar";

const ReleatedCar = ({ data }) => {

	const listingCar = [];

	const [exRate, setExRate] = useState(async () => {
		try {
			const ex_rate = await axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)    
			
			console.log('USD : ' + ex_rate.data.rates.USD);
			setExRate(ex_rate.data.rates.USD);
			//return ex_rate.data.rates.USD;
		} catch (error) {
			console.error(error);
		}
	});

	let thumbnail_ = "https://www.tc-v.com/cdn/trade/img06/cars/1689032/30564053/01.jpg";
	return (
		<>
			<Swiper
				spaceBetween={20}
				speed={1000}
				modules={[Pagination]}
				pagination={{
					el: ".car-for-rent-pag",
					spaceBetween: 10,
					clickable: true,
				}}
				breakpoints={{
					// breakpoints for responsive design
					320: {
						slidesPerView: 1,
					},
					640: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 3,
					},
					1068: {
						slidesPerView: 4,
					},
				}}
			>
				{data.slice(0, 6).map((listing) => (
					<SwiperSlide key={listing.id}>
						<div className="item">
							<div className="car-listing">
								<div className="thumb">
									{listing.tag ? (
									<>
										<div className="tag">{listing.tag}</div>
									</>
									) : undefined}

									<img
										width={284}
										height={183}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
										}}
										priority
										src={(listing.tumbnail) ? listing.tumbnail : (listing.tcv_images) ? JSON.parse(listing.tcv_images)[0] : (listing.gallery) ? JSON.parse(listing.gallery)[0] : thumbnail_ }
										alt={listing.title}
									/>
									<div className="thmb_cntnt2">
										<ul className="mb0">
											<li className="list-inline-item">
												<a className="text-white" href="#">
													<span className="flaticon-photo-camera mr3" />{" "}
													{listing.photosCount}
												</a>
											</li>
											<li className="list-inline-item">
												<a className="text-white" href="#">
													<span className="flaticon-play-button mr3" />{" "}
													{listing.videosCount}
												</a>
											</li>
										</ul>
									</div>
									<div className="thmb_cntnt3">
										<ul className="mb0">
											<li className="list-inline-item">
												<a href="#">
													<span className="flaticon-shuffle-arrows" />
												</a>
											</li>
											<li className="list-inline-item">
												<a href="#">
													<span className="flaticon-heart" />
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="details">
									<div className="wrapper">
										<h5 className="price"><PriceDisplay price={listing.price} rate={exRate} /></h5>
										<h6 className="title">
											<NavLink to={`/detail/${listing.id}`}>{ ((listing.title).length > 30) ? (listing.title).slice(0, 27) + '...' : listing.title }</NavLink>
										</h6>
										<div className="listign_review">
											<ul className="mb0">
												<li className="list-inline-item">
													{ listing.m_year }
												</li>
												<li className="list-inline-item">
													- { listing.model_code }
												</li>
												{/* {[...Array(5)].map((_, index) => (
													<li key={index} className="list-inline-item">
														<a href="#">
															<i className="fa fa-star" />
														</a>
													</li>
												))}
												<li className="list-inline-item">
													<a href="#">{listing.rating}</a>
												</li>
												<li className="list-inline-item">
													({listing.reviewsCount} reviews)
												</li> */}
											</ul>
										</div>
									</div>{" "}
									<div className="listing_footer">
										<ul className="mb0">
											<li className="list-inline-item">
												<span className="flaticon-road-perspective me-2" />
												{listing.mileage}
											</li>
											<li className="list-inline-item">
												<span className="flaticon-gas-station me-2" />
												{((listing.FuelType?.name).length > 9) ? (listing.FuelType?.name).slice(0, 9) + '...' : listing.FuelType?.name }
											</li>
											<li className="list-inline-item">
												<span className="flaticon-gear me-2" />
												{ listing.TransmissionType?.name }
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<div className="mt-3 text-center">
				<div className="car-for-rent-pag" />
			</div>
		</>
	);
};

export default ReleatedCar;
