import { NavLink } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

const ListingContent = ({ orderList }) => {
	const carListings = [
		{
			id: 1,
			make: "Volvo",
			model: "XC90",
			year: 2020,
			transmission: "Automatic",
			fuelType: "Diesel",
			price: "$129",
			imageSrc: "../../assets/images/listing/1.jpg",
		},
		{
			id: 2,
			make: "Audi",
			model: "A8 L 55",
			year: 2021,
			transmission: "Automatic",
			fuelType: "Diesel",
			price: "$129",
			imageSrc: "../../assets/images/listing/5.jpg",
		},
		{
			id: 3,
			make: "Bentley",
			model: "Bentayga V8",
			year: 2020,
			transmission: "Automatic",
			fuelType: "Diesel",
			price: "$129",
			imageSrc: "../../assets/images/listing/7.jpg",
		},
	];

	return (
		<div className="col-lg-12">
			<div className="table-responsive my_lisging_table">
				<table className="table">
					<thead className="table-light">
						<tr className="thead_row">
							<th className="thead_title pl20" scope="col">
								ID
							</th>
							<th className="thead_title" scope="col">
								Reference
							</th>
							<th className="thead_title" scope="col">
								Date
							</th>
							<th className="thead_title" scope="col">
								Type
							</th>
							<th className="thead_title" scope="col">
								Payment Status
							</th>
							<th className="thead_title" scope="col">
								Total
							</th>
							<th className="thead_title" scope="col">
								Remaining Payment
							</th>
							<th className="thead_title" scope="col">
								
							</th>
						</tr>
					</thead>
					{/* End thead */}

					<tbody>
						{orderList?.map((value) => {
							let utcDate = value.order_date;
							let localDate = new Date(utcDate);
							
							return (
								<tr key={value.id}>
									{/* <th className="align-middle pl20" scope="row">
										<div className="car-listing bdr_none d-flex mb0">
											<div className="thumb w150">
												<img
													width={150}
													height={96}
													style={{
														objectFit: "cover",
														width: "100%",
														height: "100%",
													}}
													className="img-fluid"
													src={value.imageSrc}
													alt={'Img'}
												/>
											</div>
											<div className="details ms-1">
												<h6 className="title">
													<a href="page-car-single-v1.html">{`${value.title} - ${value.m_year}`}</a>
												</h6>
												<h5 className="price">{value.price}</h5>
											</div>
										</div>
									</th> */}
									<td class="align-middle" align="center">
										<NavLink to={`/view-order/${value.id}`} className={'text-primary'}>#{ value.id }</NavLink>
									</td>
									
									<td className="align-middle">{ ((value.remark).length > 50) ? (value.remark).slice(0, 50) + '...' : value.remark }</td> 
									<td className="align-middle">{ localDate.toLocaleDateString() }</td>
									<td className="align-middle">{ value.type == 1 ? <span class="badge bg-info">Domestic</span>  : <span class="badge bg-info">Export</span>}</td>
									<td className="align-middle">{ (value.status == 0) && <span class="badge bg-danger">Cancelled</span> || (value.status == 1) && <span class="badge bg-warning">Pending</span> || <span class="badge bg-success">Completed</span>}</td>
									<td className="align-middle">{ (value.currency == 1) ? '¥' : '$' } <NumericFormat type="text" displayType="text" value={ (value.currency == 1) ? Number(value.sub_total) : value.sub_total } thousandsGroupStyle="thousand" thousandSeparator="," /></td>
									{/* <td className="align-middle">{ (value.currency == 1) ? '¥ ' + Number(value.sub_total) : '$ ' + value.sub_total } </td> */}
									{/* <td className="align-middle">{ (value.currency == 1) ? '¥ ' + Number(value.amount_remaining) : '$ ' + value.amount_remaining }</td> */}
									<td className="align-middle">{ (value.currency == 1) ? '¥' : '$' } <NumericFormat type="text" displayType="text" value={ (value.currency == 1) ? Number(value.amount_remaining) : value.amount_remaining } thousandsGroupStyle="thousand" thousandSeparator="," /> </td>
									<td className="editing_list align-middle">
										<ul>
											<li className="list-inline-item mb-1">
												<NavLink exact to={`/view-order/${value.id}`} className={'text-primary'} title="View"><span className="flaticon-view"></span></NavLink>
												{/* <a href="#" title="View">
													<span className="flaticon-view"></span>
												</a> */}
											</li>
											{/* <li className="list-inline-item mb-1">
												<a href="#" title="Edit">
													<span className="flaticon-pen"></span>
												</a>
											</li>
											<li className="list-inline-item mb-1">
												<a href="#" title="Delete">
													<span className="flaticon-trash"></span>
												</a>
											</li> */}
										</ul>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ListingContent;
