import React, { useRef, useState, useEffect } from "react";
import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useLocalStorage } from "../../../hooks/useLocalStorage";

const Form = ({t}) => {
	const navigate = useNavigate();
    const location = useLocation();
    const from = "/dashboard"; //location.state?.from?.pathname || "/dashboard";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState(''); 
    const [errMsg, setErrMsg] = useState('');

    const [userLocal, setUserLocal] = useLocalStorage("user", null);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

	const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/api/auth/register`,
                {
                    email: user,
                    password: pwd,
					first_name: firstName,
					last_name: lastName
                },
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': "*" 
                    },
                    // withCredentials: true
                }
            );

            console.log(JSON.stringify(response?.data));

            const accessToken = response?.data?.token;
            //const roles = [5150]; //response?.data?.roles;
			let roles = [];
            roles.push(response?.data?.permission_set);
            const userId = response?.data?.id;

            console.log('accessToken:' + accessToken);

            setUserLocal({ user, roles, accessToken, userId });
            setUser('');
            setPwd('');

            navigate(from, { replace: true });

        } catch (err) {
            console.log('Login Err:' + err);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

	return (
		<form onSubmit={handleSubmit} method='post'>
			<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
			<div className="row">
				<div className="col-lg-6">
					<div className="form-group">
						<label className="form-label">{ t('contact-us.contact-first-name') }</label>
						<input
							type="text"
							className="form-control"
							placeholder={ t('contact-us.contact-first-name') }
							required
							onChange={(e) => setFirstName(e.target.value)}
							value={firstName} 
						/>
					</div>
				</div>
				{/* End .col */}

				<div className="col-lg-6">
					<div className="form-group">
						<label className="form-label">{ t('contact-us.contact-last-name') }</label>
						<input
							type="text"
							className="form-control"
							placeholder={ t('contact-us.contact-last-name') }
							onChange={(e) => setLastName(e.target.value)}
							required
							value={lastName} 
						/>
					</div>
				</div>
				{/* End .col */}

				<div className="col-lg-12">
					<div className="form-group">
						<label className="form-label">{ t('contact-us.contact-email') }</label>
						<input
							type="email"
							className="form-control"
							placeholder={ t('contact-us.contact-email') }
							required
							ref={userRef} autoComplete="off"
							onChange={(e) => setUser(e.target.value)}
							value={user} 
						/>
					</div>
				</div>
				{/* End .col */}

				<div className="col-lg-6">
					<div className="form-group mb20">
						<label className="form-label">{ t('form.password') }</label>
						<input
							type="password"
							className="form-control"
							placeholder={ t('form.password') }
							onChange={(e) => setPwd(e.target.value)}
							required
							value={pwd} 
						/>
					</div>
				</div>
				{/* End .col */}

				<div className="col-lg-6">
					<div className="form-group mb20">
						<label className="form-label">{ t('form.confirm-password') }</label>
						<input
							type="password"
							className="form-control"
							placeholder={ t('form.confirm-password') }
							required
						/>
					</div>
				</div>
				{/* End .col */}
			</div>
			{/* End .row */}
			<button type="submit" className="btn btn-log btn-thm mt5">
				{ t('form.sign-up') }
			</button>
		</form>
	);
};

export default Form;
