import { useTranslation } from 'react-i18next';
import DashboardHeader from "../../components/common/dashboard/DashboardHeader";
import DashboardHeaderTop from "../../components/common/dashboard/DashboardHeaderTop";
import DashboardSidebarMenu from "../../components/common/dashboard/DashboardSidebarMenu";
import DashboardSidebarMobileMenu from "../../components/common/dashboard/DashboardSidebarMobileMenu";

import HeaderSidebar from "../../components/common/HeaderSidebar";
import MobileMenu from "../../components/common/MobileMenu";
import LoginSignupModal from "../../components/common/login-signup";

import VehicleListTable from '../../components/common/dashboard/VehicleListTable';

const Vehicles = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    const { t } = useTranslation();

    return (
        <div className="wrapper">
            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <HeaderSidebar t={t} />
            </div>
            {/* Sidebar Panel End */}

            {/* header top */}
            <DashboardHeaderTop t={t} />
            {/* End header top */}

            {/* Main Header Nav */}
            <DashboardHeader t={t} />
            {/* End Main Header Nav */}

            {/* Main Header Nav For Mobile */}
            <MobileMenu t={t} />
            {/* End Main Header Nav For Mobile */}

            {/* Our Dashbord */}
            <section className="our-dashbord dashbord bgc-f9" style={{ minHeight: '2000px'}}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xxl-10 offset-xxl-2 dashboard_grid_space">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="extra-dashboard-menu dn-lg">
                                        <div className="ed_menu_list">
                                            <ul>
                                                <DashboardSidebarMenu t={t} />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End .row */}

                            <div className="dashboard_navigationbar dn db-lg mt50">
                                <DashboardSidebarMobileMenu t={t} />
                            </div>
                            {/* End mobile navigation */}

                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="breadcrumb_content mb50">
                                        <h2 className="breadcrumb_title">My Vehicles</h2>
                                        <p>List of Purchased Vehicles.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End .row */}

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="dashboard_favorites_contents dashboard_my_lising_tabs p10-520">
                                        <VehicleListTable t={t} />
                                    </div>
                                </div>
                            </div>
                            {/* End .row */}
                        </div>
                    </div>
                </div>
            </section>
            {/* End Our Dashbord */}

            {/* Modal */}
            <div
                className="sign_up_modal modal fade"
                id="logInModal"
                data-backdrop="static"
                data-keyboard=""
                tabIndex={-1}
                aria-hidden="true"
            >
                <LoginSignupModal />
            </div>
            {/* End Modal */}
        </div>
        // End wrapper
    );
};

export default Vehicles;
