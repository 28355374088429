//import Image from "next/image";
import CopyRight from "./footer/CopyRight";
import FooterItems from "./footer/FooterItems";
import Navigation from "./footer/Navigation";

const Footer = ({t}) => {
    return (
        <section className="footer_one pt50 pb25">
            {/* <div className="container">
                <div className="row">
                    <div className="col-md-4 col-xl-7">
                        <div className="footer_about_widget text-start">
                            <div className="logo mb40 mb0-sm">
                                <img
                                    width={140}
                                    height={45}
                                    className="logo2 img-fluid"
                                    src="/assets/images/logo.png"
                                    alt="header-logo.png"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8 col-xl-5">
                        <div className="footer_menu_widget text-start text-md-end mt15">
                            <ul>
                                <Navigation />
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* End .container */}

            {/* <hr /> */}
            <div className="container pt80 pt20-sm pb70 pb0-sm">
                <FooterItems t={t} />
                {/* End .row */}
            </div>
            {/* End .container */}
            <CopyRight />
        </section>
    );
};

export default Footer;
