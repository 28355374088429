import { NavLink } from 'react-router-dom'
const FooterItems = ({t}) => {
	return (
		<div className="row">
			<div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
				
				<div className="footer_about_widget">
					<h5 className="title">{ t('footer.sales-office-nogi') }</h5>
					<p>
						{ t('footer.footer-address-line-1') } <br/> 
						{ t('footer.footer-address-line-2') } 
						<br />
						{ t('footer.footer-address-line-3') }, 
						<br />
						{ t('footer.footer-address-line-4') }
					</p>
				</div>
				{/* End widget */}
			</div>
			{/* End .col */}


			<div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
				<div className="footer_contact_widget">
					<h5 className="title">{ t('footer.contact-us') }</h5>
					<p className="footer_phone">0280-33-6090</p>
                    <p className="footer_phone">080-4631-9655</p>
					{/* <p>hello@voiture.com</p> */}
					<p><a href="mailto:info@kagamotors.jp" style={{ color: "white" }} >info@kagamotors.jp</a> / <a href="mailto:export@kagamotors.jp" style={{ color: "white" }} >export@kagamotors.jp</a></p>
				</div>
			</div>
			{/* End .col */}

			<div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
				<div className="footer_contact_widget">
					<h5 className="title">{ t('footer.opening-hours') }</h5> 
					<p>
						{ t('footer.monday') } - { t('footer.friday') }: 08.30 - 17:00
                    	<br></br>
                    	{ t('footer.saturday') } : 09.00 - 17:00
                    	<br></br>
                    	{ t('footer.sunday') } { t('footer.closed') }
                    </p>

					
				</div>
			</div>
			{/* End .col */}

			<div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
				<div className="footer_contact_widget">
					<h5 className="title">{ t('footer.useful-links') }</h5>
					<p>
						<NavLink href={'/terms'} className={'text-white'}>{ t('footer.terms-conditions') }</NavLink>
                    	<br />
                    	<NavLink href={'/terms'} className={'text-white'}>{ t('footer.faq') }</NavLink>
                    </p>
					{/* <li className="list-inline-item" key={1}>
						<NavLink href={'/terms'} className="title">Terms & Conditions</NavLink>
					</li>
					<li className="list-inline-item" key={1}>
						<NavLink href={'/terms'} className="title">Faq</NavLink>
					</li> */}
					{/* <h5 className="title">KEEP IN TOUCH</h5>
					<form className="footer_mailchimp_form">
						<div className="wrapper">
							<div className="col-auto">
								<input
									type="email"
									className="form-control"
									placeholder="Enter your email..."
									required
								/>
								<button type="submit">GO</button>
							</div>
						</div>
					</form>
					<p>Get latest updates and offers.</p> */}
				</div>
			</div>
			{/* End .col */}
		</div>
	);
};

export default FooterItems;
