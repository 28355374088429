import Footer from "../components/common/Footer";
import DefaultHeader from "../components/common/DefaultHeader";
import HeaderSidebar from "../components/common/HeaderSidebar";
import HeaderTop from "../components/common/HeaderTop";
import MobileMenu from "../components/common/MobileMenu";
import LoginSignupModal from "../components/common/login-signup";
import Map from "../components/common/Map";
import Address from "../components/pages/contact/Address";
import Form from "../components/pages/contact/Form";

import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

export const metadata = {
	title: "Contact || Voiture - Automotive & Car Dealer NextJS Template",
};

const Contact = () => {

	window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

	const { t } = useTranslation();

	<Helmet>
        <title>Contact Us || KAGA Motors - Automotive & Car Dealer in Japan</title>
        <meta name="description" content="Contact KAGA Motors - Automotive & Car Dealer in Japan." />
        <meta name="theme-color" content="#008f68" />
		<meta property="og:title" content={'Contact Us || KAGA Motors - Automotive & Car Dealer'} />
        <meta property="og:description" content={'Contact KAGA Motors - Automotive & Car Dealer in Japan.'} />
        <meta property="og:image" content={'https://kagamotors.jp/assets/images/logo.png'} />
        <meta property="og:url" content={'https://kagamotors.jp/contact'} />
        <meta property="og:type" content="website" />
    </Helmet>

	return (
		<div className="wrapper">
			<div
				className="offcanvas offcanvas-end"
				tabIndex="-1"
				id="offcanvasRight"
				aria-labelledby="offcanvasRightLabel"
			>
				<HeaderSidebar t={t}  />
			</div>
			{/* Sidebar Panel End */}

			{/* header top */}
			<HeaderTop t={t}  />
			{/* End header top */}

			{/* Main Header Nav */}
			<DefaultHeader t={t}  />
			{/* End Main Header Nav */}

			{/* Main Header Nav For Mobile */}
			<MobileMenu t={t}  />
			{/* End Main Header Nav For Mobile */}

			{/* Our Contact */}
			{/* //bgc-f9 */}
			<section className="our-contact p0 mt100 mt70-992 mb100 slider_dsk"> 
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<Address t={t}  />
						</div>
						{/* End .col-md-4 */}

						<div className="col-md-8">
							<div className="form_grid">
								<div className="wrapper">
									<h3 className="title mb20">{ t('contact-us.contact-form') }</h3>
									<Form t={t}  />
								</div>
							</div>
						</div>
						{/* End .col-md-8 */}

					</div>
					{/* End .row */}
				</div>
				{/* End .container */}
			</section>

			{/* Moile Version */}
			<section className="our-contact p0 mt100 mt80-992 mb100 slider_mob"> 
				<div className="container">
					<div className="row">
						<div className="col-md-8 pl30">
							<div className="form_grid">
								<div className="wrapper">
									<h3 className="title mb20">{ t('contact-us.contact-form') }</h3>
									<Form t={t}  />
								</div>
							</div>
						</div>
						{/* End .col-md-8 */}

						<div className="col-md-4 pl30 mt30">
							<Address t={t}  />
						</div>
						{/* End .col-md-4 */}

					</div>
					{/* End .row */}
				</div>
				{/* End .container */}
			</section>

			{/* End Our Contact */}

			{/* <!-- Our Contact --> */}
			<section className="our-contact p0 mt0 mt70-992">
				<div className="container-fluid p0">
					<div className="row">
						<div className="col-lg-12">
							<div className="h600 map_in">
								<Map />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- End Our Contact --> */}

			{/* Our Footer */}
			<Footer t={t} />
			{/* End Our Footer */}

			{/* Modal */}
			<div
				className="sign_up_modal modal fade"
				id="logInModal"
				data-backdrop="static"
				data-keyboard="false"
				tabIndex={-1}
				aria-hidden="true"
			>
				<LoginSignupModal />
			</div>
			{/* End Modal */}
		</div>
		// End wrapper
	);
};

export default Contact;
