import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';

const CurrencyContext = createContext();

function fetchExRate() {
    
    var conversionRates = {
        USD: 0,
        JPY: 1, // Example conversion rate
        GBP: 0.72, // Example conversion rate
        // Add more conversion rates here
    };

    try {
        const ex_rate = axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)    
        
        conversionRates.USD = ex_rate.rates.USD;
        console.log('USD : ' + conversionRates.USD);
    } catch (error) {
        console.error(error);
    }
}

export function CurrencyProvider({ children }) {
    const [currency, setCurrency] = useState('JPY');

    return (
        <CurrencyContext.Provider value={{ currency, setCurrency }}>
            {children}
        </CurrencyContext.Provider>
    );
}

export function useCurrency() {
    return useContext(CurrencyContext);
}
