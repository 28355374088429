import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const SubCategoryModal = ({t}) => {
    const navigate = useNavigate();
	
    const categories = [
        {
			imgSrc: "/assets/images/category/sub/truck/all.png",
			title: t('sub-category-truck.all'), //"All",
			value: "All",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/aerial-platform.png",
			title: t('sub-category-truck.aerial-platform'),
			value: "Aerial Platform",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/car-carrier.png",
			title: t('sub-category-truck.car-carrier'), 
			value: "Car Carrier",
			delay: 200,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/concrete-pump-truck.png",
			title: t('sub-category-truck.concrete-pump-truck'), 
			value: "Concrete Pumping Truck",
			delay: 300,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/crane.png",
			title: t('sub-category-truck.crane'), 
			value: "Crane",
			delay: 400,
			listing: "16,869",
		},
        {
			imgSrc: "/assets/images/category/sub/truck/dump.png",
			title: t('sub-category-truck.dump'), //"All",
			value: "Dump",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/flatbody.png",
			title: t('sub-category-truck.flatbody'), //"Bus",
			value: "Flatbody",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/garbage-truck.png",
			title: t('sub-category-truck.garbage-truck'), 
			value: "Garbage Truck",
			delay: 200,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/hook-lift.png",
			title: t('sub-category-truck.hook-lift'), 
			value: "Hook Lift",
			delay: 300,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/mixer.png",
			title: t('sub-category-truck.mixer'), 
			value: "Mixer",
			delay: 400,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/other.png",
			title: t('sub-category-truck.other'), //"All",
			value: "Other",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/pickup.png",
			title: t('sub-category-truck.pickup'), //"Bus",
			value: "Pickup",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/refrigerated-freezer.png",
			title: t('sub-category-truck.refrigerated-freezer'), 
			value: "Refrigerated / Freezer",
			delay: 200,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/sefety-loader.png",
			title: t('sub-category-truck.sefl-loader'), 
			value: "Sefl Loader / Safety Loader",
			delay: 300,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/tanker.png",
			title: t('sub-category-truck.tanker'), 
			value: "Tanker",
			delay: 400,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/tractor.png",
			title: t('sub-category-truck.tractor'), //"All",
			value: "Tractor",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/trailer.png",
			title: t('sub-category-truck.trailer'), //"Bus",
			value: "Trailer / Trailer Set",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/vaccum-truck.png",
			title: t('sub-category-truck.vaccum-truck'), 
			value: "Vaccum Truck",
			delay: 200,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/wing.png",
			title: t('sub-category-truck.wing'), 
			value: "Wing",
			delay: 400,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/sub/truck/wrecker.png",
			title: t('sub-category-truck.wrecker'), 
			value: "Wrecker",
			delay: 400,
			listing: "16,869",
		},
	];
	
    const handleSearch = async (e, name) => {
		e.preventDefault();

		// if (name === "Other")
		// 	name = "Unspecified";
		// else if (name === "Bus")
		// 	name = "Bus";
		// else if (name === "Truck")
		// 	name = "Truck";
		// else if (name === "Car")
		// 	name = "Sedan";
		// else
		// 	name = "Unspecified";

		console.log('name : ' + name);
		
		let id_ = 0;
		let sub_id_ = 0;


		try {
			if (name === "All"){
				const res = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/category/name`, { params: { name: 'Truck'} });
	
				if (res)
					id_ = res.data.id;
		
				console.log('id_ ' + id_);

				//navigate(`${process.env.PUBLIC_URL}/listing?bodytype=${id_}`);
				window.location.href = `${process.env.PUBLIC_URL}/listing?bodytype=${id_}`;
			}else{
				const res = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/sub-category/name`, { params: { name: name} });
	
				if (res)
					id_ 	= res.data.category_id;
					sub_id_ = res.data.id;
		
				console.log('id_ ' + sub_id_);

				//navigate(`${process.env.PUBLIC_URL}/listing?bodytype2=${id_}`);
				window.location.href = `${process.env.PUBLIC_URL}/listing?bodytype=${id_}&bodytype2=${sub_id_}`;
			}

		} catch (error) {
			console.error(error);
		}

    }

	return (
		<div className="modal-dialog modal-dialog-centered modal-dialog-lg modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<button
						type="button"
						className="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					/>
				</div>
				{/* End Modal close button */}

				<div className="modal-body container p60">
					<div className="row">
						{/* <div className="col-lg-12"> */}
                            {categories.map((category, index) => { return (
                                
                                <div
                                    key={index} //col-6 col-sm-6 col-md-4 col-lg col-xl
                                    className={`col-xs-3 col-md-3`}
                                    data-aos="fade-up"
                                    data-aos-delay={category.delay}
                                    onClick={e => handleSearch(e, category.value)}
                                >
                                    <div className="category_item home7_style pt35" style={{ paddingTop: '35px' }}>
                                        {/* <div className="thumb">
                                            <img
                                                width={300} //150
                                                height={60} //58
                                                style={{ objectFit: "contain" }}
                                                src={category.imgSrc}
                                                alt={`${index + 1}.png`}
                                            />
                                        </div> */}
                                        <div className="details">
                                            <p className="title">
                                                <NavLink to="" onClick={e => handleSearch(e, category.value)}>{category.title}</NavLink>
                                                {/* <p className="para">{category.listing} Listings</p> */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            )})}
						{/* </div> */}
					</div>
					{/* End .row */}

					
				</div>
				{/* End modal-body */}
			</div>
		</div>
	);
};

export default SubCategoryModal;
