import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../../../services/crudService'

import Pagination from "../../Pagination";
import { NumericFormat } from 'react-number-format';

const ListingTabContent = ({ data }) => {
	return (
		<>
			<div className="row">
				<div className="col-lg-12">
                    <h4 className="title mb30">Additional Costs</h4>
				</div>
				{/* <!-- Tab panes --> */}

				<div className="col-lg-12">
					<div className="tab-content row" id="nav-tabContent">
						<div
							className="tab-pane fade show active"
							id="nav-home"
							role="tabpanel"
							aria-labelledby="nav-home-tab"
						>
                            {/* Detail Table */}
							<div className="col-lg-12">
                                <div className="table-responsive my_lisging_table">
                                    <table className="table">
                                        <thead className="table-light">
                                            <tr className="thead_row">
                                                <th className="thead_title pl20" scope="col">
                                                    Cost
                                                </th>
                                                <th className="thead_title" scope="col">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        {/* End thead */}

                                        <tbody>
                                            {data?.map((item) => (
                                                <tr key={item.id}>
                                                    <td className="align-middle">{item.cost}</td>
                                                    <td className="align-middle"><NumericFormat type="text" displayType="text" value={ item.amount } thousandsGroupStyle="thousand" thousandSeparator="," /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* End Detail Table */}
						</div>
						{/* End tab-content */}
					</div>
					{/* End tab-content */}

					{/* <div className="mbp_pagination mt10">
						<Pagination />
					</div> */}
					{/* Pagination */}
				</div>
			</div>
		</>
	);
};

export default ListingTabContent;
