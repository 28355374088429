import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useEffect } from "react";


const Category1 = ({t}) => {
	const navigate = useNavigate();
	const [categoryList, setCategoryList] = useState([]);

	const categories = [
		{
			imgSrc: "/assets/images/category/bus.png",
			title: t('category.bus'), //"Bus",
			value: "Bus",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/truck.png",
			title: t('category.truck'), 
			value: "Truck",
			delay: 200,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/van.png",
			title: t('category.van-minivan'), 
			value: "Van",
			delay: 300,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/car.png",
			title: t('category.sedan'), 
			value: "Car",
			delay: 400,
			listing: "16,869",
		},
		// {
		// 	imgSrc: "/assets/images/category/parts.png",
		// 	title: t('category.parts-accessories'), 
		// 	value: "Parts & Accessories",
		// 	delay: 500,
		// 	listing: "16,869",
		// },
	];

	//Get all bodytypes
	//Category List
	// useEffect(() => {
	// 	console.log('UseEffect Triggered')
	// 	axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/category/basic`, { params: { name: }})
	// 	.then((res) => {
	// 		setCategoryList(res.data);
	// 	})
	// 	.catch((err) => {
	// 		console.error(err);
	// 	})
	// }, [])

	//map into Categories

	const handleSearch = async (e, name) => {
		e.preventDefault();

		if (name === "Van")
			name = "Van / Minivan";
		else if (name === "Bus")
			name = "Bus";
		else if (name === "Truck")
			name = "Truck";
		else if (name === "Car")
			name = "Sedan";
		else
			name = "Unspecified";

		console.log('name : ' + name);
		
		let id_ = 0;


		try {
			const res = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/category/name`, { params: { name: name} });

			if (res)
				id_ = res.data.id;
	
			console.log('id_ ' + id_);
	
			navigate(`${process.env.PUBLIC_URL}/listing?bodytype=${id_}`);

		} catch (error) {
			console.error(error);
		}

    }

	const handleSearch_ = (e, name) => {
		e.preventDefault();

		if (name === "Van")
			name = "Van / Minivan";
		if (name === "Bus")
			name = "Bus";

		console.log('name : ' + name);
		
		let rec_ = categoryList.filter((item) => item.name === name);

		console.log('rec_.id ' + rec_.id);

		let id_ = rec_.id;

        navigate(`${process.env.PUBLIC_URL}/listing?bodytype=${id_}`);
    }

	return (
		<>
			{categories.map((category, index) => { return (
				(category.value == "Truck") ?
					<div
						key={index} //col-6 col-sm-6 col-md-4 col-lg col-xl
						className={`col-6 col-sm-6 col-md-4 col-lg col-xl`}
						data-aos="fade-up"
						data-aos-delay={category.delay}
						data-bs-toggle="modal"
						data-bs-target="#subCatModal"
					>
						<div className="category_item home7_style">
							<div className="thumb">
								<img
									width={300} //150
									height={60} //58
									style={{ objectFit: "contain" }}
									src={category.imgSrc}
									alt={`${index + 1}.png`}
								/>
							</div>
							<div className="details">
								<p className="title">
										<a
											href="#"
											data-bs-toggle="modal"
											data-bs-target="#subCatModal"
										>
											{category.title}
										</a>
									
									{/* <p className="para">{category.listing} Listings</p> */}
								</p>
							</div>
						</div>
					</div>
					:
					<div
						key={index} //col-6 col-sm-6 col-md-4 col-lg col-xl
						className={`col-6 col-sm-6 col-md-4 col-lg col-xl`}
						data-aos="fade-up"
						data-aos-delay={category.delay}
						onClick={e => handleSearch(e, category.value)}
					>
						<div className="category_item home7_style">
							<div className="thumb">
								<img
									width={300} //150
									height={60} //58
									style={{ objectFit: "contain" }}
									src={category.imgSrc}
									alt={`${index + 1}.png`}
								/>
							</div>
							<div className="details">
								<p className="title">
									<NavLink to="" onClick={e => handleSearch(e, category.value)}>{category.title}</NavLink>
									{/* <p className="para">{category.listing} Listings</p> */}
								</p>
							</div>
						</div>
					</div>
			)})}
		</>
	);
};

export default Category1;
