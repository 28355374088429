const AboutTextBlock = ({t}) => {
	return (
		<>
			<div className="col-lg-6">
				<div className="about_thumb mb30-md">
					<img
						width={636}
						height={667}
						priority
						style={{ objectFit: "cover" }}
						className="thumb1"
						src="./assets/images/about/about1.png"
						alt="1.jpg"
					/>
					{/* <img
						width={365}
						height={238}
						priority
						style={{ objectFit: "cover" }}
						className="img-fluid thumb2"
						src="./assets/images/about/about2.jpg"
						alt="2.jpg"
					/> */}
				</div>
			</div>
			{/* End .col */}
			<div className="col-lg-5 offset-lg-1">
				<div className="">
					<h2 className="title mb20">{t('about-us.welcome-to-kaga')}</h2>
					<p className="mb30">
						<b>{t('about-us.company-profile')}</b>
						<br/>{t('about-us.kagamotors-company-limited')}
					</p>
					<p className="mb30">
						<b>{t('about-us.address')}</b>
						<br/>{t('about-us.head-office')}
						<br/>{t('about-us.nogi-sales-office')}
						<br/>{t('about-us.niigata-sales-office')}
					</p>
					<p className="mb30">
						<b>{t('about-us.telephone')}</b>
						<br/>0280-33-6090
						<br/>080-4631-9655 (Line, WhatsApp, Viber)
					</p>
					<p className="mb30">
						<b>{t('about-us.fax')}</b>	
						<br/>0280-33-6099
					</p>
					<p className="mb30">
						<b>{t('about-us.email')}</b>
						<br/><a href="mailto:info@kagamotors.jp">info@kagamotors.jp</a>
						<br/><a href="mailto:export@kagamotors.jp">export@kagamotors.jp</a>
					</p>
					<p className="mb30">
						<b>{t('about-us.founder')}</b>	
						<br/>{t('about-us.kaga-chaminda')}
					</p>
					<p className="mb30">
						<b>{t('about-us.founding-date')}</b>	
						<br/>{t('about-us.jan-4-2004')}
					</p>
					<p className="mb30">
						<b>{t('about-us.type-buisness')}</b>
						<br/>{t('about-us.used-vehicles-sales-export')}
					</p>
					<p className="mb50">
						<b>{t('about-us.export-destination')}</b>
						<br/>{t('about-us.country-list')}
					</p>
					{/* <a className="btn btn-thm about-btn" href="#">
						Learn More
					</a> */}

				</div>
			</div>
			{/* End .col */}
		</>
	);
};

export default AboutTextBlock;
