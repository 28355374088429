import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { NavLink, useNavigate, useLocation, useParams } from 'react-router-dom'
import CrudService from '../services/crudService';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { NumericFormat } from 'react-number-format';

import Footer from "../components/common/Footer";
import DefaultHeader from "../components/common/DefaultHeader";
import HeaderSidebar from "../components/common/HeaderSidebar";
import HeaderTop from "../components/common/HeaderTop";
import MobileMenu from "../components/common/MobileMenu";
import LoginSignupModal from "../components/common/login-signup";
import BreadCrumb from "../components/listing/listing-single/BreadCrumb";
import ShareMeta from "../components/listing/listing-single/ShareMeta";
//import ProductGallery from "../components/listing/listing-single/listing-single-v1/ProductGallery";
import Overview from "../components/listing/listing-single/Overview";
import Features from "../components/listing/listing-single/Features";
import ReleatedCar from "../components/listing/listing-single/ReleatedCar";
import Map from "../components/common/Map";

import ProductGallery from "../components/listing/listing-single/listing-single-v3/ProductGallery";
import Descriptions from "../components/listing/listing-single/Descriptions";
import SellerDetail2 from "../components/listing/listing-single/sidebar/SellerDetail2";
import ImageGallery from '../components/listing/listing-single/Gallery';
import PriceDisplay from '../components/common/priceDisplay';

export const metadata = {
    title:
        "Listing Single V3 || Voiture - Automotive & Car Dealer NextJS Template",
};

const Detail = () => {
    const { t } = useTranslation();
    <Helmet>
        <title>Detail || KAGA Motors - Automotive & Car Dealer in Japan</title>
        <meta name="description" content="Vehicle Details in KAGA Motors - Automotive & Car Dealer in Japan." />
        <meta name="theme-color" content="#008f68" />
		<meta property="og:title" content={'Detail || KAGA Motors - Automotive & Car Dealer'} />
        <meta property="og:description" content={'Vehicle Details in KAGA Motors - Automotive & Car Dealer in Japan.'} />
        <meta property="og:image" content={'https://kagamotors.jp/assets/images/logo.png'} />
        <meta property="og:url" content={'https://kagamotors.jp/detail/'} />
        <meta property="og:type" content="website" />
    </Helmet>

    const [id, setId] = useState(0);
    const [listingList, setListingList] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [relatedList, setRelatedList] = useState([]); 

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [imgArr, setImgArr] = useState([]);

    const [exRate, setExRate] = useState(async () => {
		try {
			const ex_rate = await axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)    
			
			console.log('USD : ' + ex_rate.data.rates.USD);
			setExRate(ex_rate.data.rates.USD);
			//return ex_rate.data.rates.USD;
		} catch (error) {
			console.error(error);
		}
	});

    var bodyArr = [];



    console.log('ID : ' + params.id);

    useEffect(() => {
		console.log('ID : ' + params.id);
        setId(params.id);
        axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/listing/${params.id}`)
            .then((res) => {
                setListingList(res.data);

                setImgArr([]);
                // for (let i = 0; i < JSON.parse(res.data.gallery).length; i++) {
                //     //console.log('Gallery:' + JSON.parse(res.data.gallery)[i]);
                //     setImgArr(imgArr => [...imgArr, JSON.parse(res.data.gallery)[i]]);
                // }

                if (res.data.gallery) {
                    for (let i = 0; i < JSON.parse(res.data.gallery).length; i++) {
                        ///console.log('Gallery:' + JSON.parse(res.data.gallery)[i]);
                        setImgArr(imgArr => [...imgArr, JSON.parse(res.data.gallery)[i]]);
						//setImgArr(imgArr => [...imgArr, process.env.REACT_APP_API_END_POINT + '/' + JSON.parse(res.data.gallery)[i]]);
                    }
                }
                else if (res.data.tcv_images) {
                    for (let i = 0; i < JSON.parse(res.data.tcv_images).length; i++) {
                        //console.log('TVC_Image:' + JSON.parse(res.data.tcv_images)[i]);
                        setImgArr(imgArr => [...imgArr, JSON.parse(res.data.tcv_images)[i]]);
                    }
                }
                
                // if (res.data.gallery){
                //     let gi_ = 0;
                //     for (let i = 0; i < JSON.parse(res.data.gallery).length; i++) {
                //         setImgArr(imgArr => [...imgArr, process.env.REACT_APP_API_END_POINT + '/' + JSON.parse(res.data.gallery)[i]]); //process.env.PUBLIC_URL + 
                //         gi_ = i;
                //     }
                //     if (res.data.tcv_images){
                //         gi_ += 1;
                //         for (let i = gi_; i < JSON.parse(res.data.tcv_images).length; i++) {
                //             setImgArr(imgArr => [...imgArr, JSON.parse(res.data.tcv_images)[i]]);
                //         }
                //     }

                // }
                // else if (res.data.tcv_images) {
                //     for (let i = 0; i < JSON.parse(res.data.tcv_images).length; i++) {
                //         setImgArr(imgArr => [...imgArr, JSON.parse(res.data.tcv_images)[i]]);
                //     }
                // }
            })
			.catch((err) => {
				console.error(err);
			})

    }, [params.id]);

    const myArray = imgArr.map((name, index) => ({ imageSrc: name, videoId: listingList?.external_link_1, width: 320, height: 174 }));

	var utcDate = listingList.createdAt;  // ISO-8601 formatted date returned from server
    var localDate = new Date(utcDate);

    const curr_lang = i18next.language;
    var body_ = (curr_lang == 'jp') ? (listingList.body_jp != null) ? listingList.body_jp : listingList.body : listingList.body;
    
    if (body_)
        bodyArr = body_.split('{BR}');
	

	useEffect(() => {

		if (listingList.features) {
			axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/feature/filtered`, {
				params: {
					features: listingList.features,
				}
			})
			.then((res) => {
				//console.log(res);
				setFeatureList(res.data);
			})
			.catch((err) => {
				console.error(err);
			})
		}

		//Fetch the related Vehicles
		axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/listing?bodytype=${listingList.category_id}&pageSize=5`)
		.then((res) => {
			setRelatedList(res.data.data);
		})
		.catch((err) => {
			console.error(err);
		})
	
	}, [listingList])

	const tableHeadStyle = {
        backgroundColor: '#f2f2f2',
        width: '40%'
		// fontWeight: 'bold'
    };

    const handleViewReport = async () => {

		try {
            const response = await CrudService.GetFileData(navigate, location, 'pdf/listing-client', 
            {
                id: params.id
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'vehicle_information_external.pdf');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the PDF file:', error);
        }
	}

	window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    return (
        <div className="wrapper">
            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <HeaderSidebar t={t} />
            </div>
            {/* Sidebar Panel End */}

            {/* header top */}
            <HeaderTop t={t} />
            {/* End header top */}

            {/* Main Header Nav */}
            <DefaultHeader t={t} />
            {/* End Main Header Nav */}

            {/* Main Header Nav For Mobile */}
            <MobileMenu t={t} />
            {/* End Main Header Nav For Mobile */}

            {/* Agent Single Grid View */}
            <section className="our-agent-single bgc-f9 pb90 mt70-992 pt30">
                <div className="container">
                    <div className="row mb30">
                        <div className="col-xl-12">
                            <div className="breadcrumb_content style2">
                                <BreadCrumb t={t} />
                            </div>
                        </div>
                    </div>
                    {/* End .row bradcrumb */}

                    <div className="row mb30">
                        <div className="col-lg-7 col-xl-8">
                            <div className="single_page_heading_content">
                                <div className="car_single_content_wrapper">
                                    <ul className="car_info mb20-md">
                                        <li className="list-inline-item">
                                            {/* { listingList.is_sold == true ? 'Sold' : 'In Stock' } */}
                                            <a href="#">{ listingList.vcondition == 1 ? 'New' : 'Used' }</a>
                                        </li>
                                        {/* <li className="list-inline-item">
                                            <a href="#">
                                                <span className="flaticon-clock-1 vam" />{ localDate.toLocaleDateString() }
                                            </a>
                                        </li> */}
                                        <li className="list-inline-item bubble">
                                            <a href="#">{ listingList.availability == 1 ? 'In Stock' : listingList.availability == 2 ? 'Sold' : 'Unavailable' }</a>
                                        </li>
                                        {/* <li className="list-inline-item">
                                            <a href="#">
                                                <span className="flaticon-eye vam" />
                                                13102
                                            </a>
                                        </li> */}
                                    </ul>
                                    <h2 className="title">{ listingList.title }</h2>
                                    <p className="para">
                                        Ref #. <b>{listingList.ref_no}</b> &nbsp; | &nbsp; {listingList.m_year} &nbsp; | &nbsp; {listingList.Model?.name} &nbsp; | &nbsp; <b>
                                            { (listingList.chng_odometer) && t('stock.changed-odometer') + ': Yes' } 
                                            { (listingList.chng_odometer != true) && t('stock.mileage') + ': '} 
                                            { (listingList.chng_odometer != true) && <NumericFormat type="text" displayType="text" value={listingList.mileage} thousandsGroupStyle="thousand" thousandSeparator="," /> }</b>
                                            { (listingList.chng_odometer != true) && ' km'}
                                        {/* {t('stock.changed-odometer')}: <b>{(listingList.chng_odometer) ? t('stock.changed-odometer') || ': Yes' : 'No'}</b> */}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* End .col-lg-7 */}

                        <div className="col-lg-5 col-xl-4">
                            <div className="single_page_heading_content text-start text-lg-end">
                                <div className="share_content">
                                    {/* <ShareMeta /> */}
                                </div>
                                <div className="price_content">
                                    <div className="price mt60 mb10 mt10-md">
                                        <h3>
                                            {(listingList.price_sale) &&
												<small className="mr15">
													<del>{listingList.price_sale}</del>
												</small>
											}
											{/* ¥ {Number(listingList.price)} */}
                                            {
                                                (listingList.availability != 2 ) &&
                                                    <PriceDisplay price={listingList.price} rate={exRate} />
                                            }
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End col-lg-5 */}
                    </div>
                    {/* End .row */}

                    <div className="row">
                        <div className="col-xl-6 mb30">
                            <ProductGallery data={myArray}  />
                            {/* End Car Gallery */}
                        </div>
                        {/* End .col-xl-6 */}

                        <div className="col-md-6 col-xl-3">
                            <div className="opening_hour_widgets p30 mb30">
                                <div className="wrapper">
                                    <Overview listingList={listingList}  t={t} />
                                    {/* --- */}
                                </div>
                            </div>
                            {/* End opening_hour_widgets */}
                        </div>
                        {/* End col-xl-3 */}

                        <div className="col-md-6 col-xl-3">
                            <div className="listing_single_description">
                                <h4 className="mb30">
                                    {/* Description{" "} */}
                                    {t('stock.description')}
                                </h4>
                                <ul >
									{
										bodyArr.map((item) => {
											return (
												<li> - {item}</li>
											)
										})
									}
								</ul>
                            </div>
                            {/* End car descriptions */}

                            <div className="offer_btns">
                                <div className="text-end">
                                    <button className="btn btn-thm ofr_btn1 btn-block mt0 mb20"  onClick={() => navigate(`/contact?ref=${listingList.id}&refno=${listingList.ref_no}`) }>
                                        <span className="flaticon-coin mr10 fz18 vam" />
                                        {/* Make an Inquiry */}
                                        {t('stock.make-inquiry')}
                                    </button>
                                    <button className="btn ofr_btn2 btn-block mt0 mb20 service_mob" onClick={handleViewReport}>
                                        <span className="flaticon-profit-report mr10 fz18 vam" />
                                        {/* Vehicle Info Document */}
                                        {t('stock.vehicle-info-document')}
                                    </button>
                                </div>
                            </div>
                            {/* End offer_btn
               */}
                            {/* <div className="sidebar_seller_contact mb30">
                                <SellerDetail2 />
                            </div> */}
                        </div>
                        {/* End col-xl-3 */}
                    </div>
                    {/* End .row top portion*/}

                    <div className="row">
                        <div className="col-lg-6 col-xl-6">
                            <div className="user_profile_service">
                                {/* <Features /> */}
                                <div className="col-lg-12">
                                    <h4 className="title">{t('stock.features')}</h4>
                                </div>
                                <div className="icons">
                                    { featureList?.map((item) => {
                                        //btn-light
                                        return ( <a className="btn btn-outline-success btn-sm border mt-1 mb-1 icons me-1" href="javascript:void(0);"> {item.name}</a> )
                                    })}    
                                </div>
                                {/* <hr />
                                <div className="row">
                                    <div className="col-lg-12">
                                        <a className="fz12 tdu color-blue" href="#">
                                            View all features
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                            {/* End user profile service */}

                            {/* <ConsumerReviews /> */}
                            {/* End ConsumerReviews */}

                            <div className="listing_single_description mt30">
                                <h4 className="mb30">
                                    {/* Gallery{" "} */}
                                    {t('stock.gallery')}
                                    {/* <span className="float-end body-color fz13">ID #9535</span> */}
                                </h4>
                                <div className='row'>
                                <ImageGallery data={myArray} />
                                {/* { 
                                    myArray.map((slide, index) => (
                                        <div className='col-xs-3 col-sm-6 col-lg-4 col-xl-4 mb5'>
                                            <img src={slide.imageSrc} className='img-fluid' />
                                        </div>
                                    ))
                                } */}
                                </div>
                            </div>
                            {/* End car descriptions */}
                        </div>
                        {/* End .col-xl-6 */}

                        <div className="col-lg-6 col-xl-6">
                            <div className="listing_single_description mt30" hidden>
                                <h4 className="mb30">
                                    {/* Description{" "} */}
                                    {t('stock.description')}
                                    {/* <span className="float-end body-color fz13">ID #9535</span> */}
                                </h4>
                                {/* <Descriptions /> */}
                                <ul >
									{
										bodyArr.map((item) => {
											return (
												<li> - {item}</li>
											)
										})
									}
								</ul>
                            </div>
                            {/* End car descriptions */}

                            <div className="listing_single_description">
                                <h4 className="mb30">
                                    {/* Video{" "} */}
                                    {t('stock.video')}
                                </h4>
                                    <p><NavLink to={`https://youtu.be/${listingList.external_link_1}`}>{`https://youtu.be/${listingList.external_link_1}`}</NavLink></p>
                                    <iframe width="560" height="315" src={`https://www.youtube.com/embed/${listingList.external_link_1}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>

                            <div className="user_profile_location">
                                <h4 className="title">{ t('stock.location') }</h4>
                                <div className="property_sp_map mb40">
                                    <div className="h400 bdrs8 map_in" id="map-canvas">
                                        <Map />
                                    </div>
                                </div>
                                <div className="upl_content d-block d-md-flex">
                                    <p className="float-start fn-sm mb20-sm">
                                        <span className="fas fa-map-marker-alt pr10 vam" />
                                        639-24 Kawada, Nogi, Shimotsuga District, Tochigi 329-0105, Japan
                                    </p>
                                    {/* <button className="btn location_btn">Get Direction</button> */}
                                </div>
                            </div>
                            {/* End Location */}

                            {/* <ReviewBox /> */}
                            {/* End ReviewBox */}
                        </div>
                        {/* End col-xl-6 */}
                    </div>
                    {/* End .row */}
                </div>
                {/* End .container */}
            </section>
            {/* End Agent Single Grid View */}

            {/* Car For Rent */}
            <section className="car-for-rent bb1">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="main-title text-center text-md-start mb10-520">
                                <h2 className="title">{t('stock.related-vehicles')}</h2>
                            </div>
                        </div>
                        {/* End .col-sm-6 */}

                        <div className="col-sm-6">
                            <div className="text-center text-md-end mb30-520">
                                <NavLink to="/listing" className="more_listing">
                                    {t('stock.show-all')}
                                    <span className="icon">
                                        <span className="fas fa-plus" />
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                        {/* End .col-sm-6 */}
                    </div>
                    {/* End .row */}

                    <div className="col-lg-12">
                        <div
                            className="home1_popular_listing home3_style"
                            data-aos-delay="100"
                        >
                            <div className="listing_item_4grid_slider nav_none">
                                <ReleatedCar data={relatedList} />
                            </div>
                        </div>
                    </div>
                    {/* End .col-lg-12 */}
                </div>
                {/* End .container */}
            </section>
            {/* End Car For Rent */}

            {/* Our Footer */}
            <Footer  t={t} />
            {/* End Our Footer */}

            {/* Modal */}
            <div
                className="sign_up_modal modal fade"
                id="logInModal"
                data-backdrop="static"
                data-keyboard=""
                tabIndex={-1}
                aria-hidden="true"
            >
                <LoginSignupModal />
            </div>
            {/* End Modal */}
        </div>
        // End wrapper
    );
};

export default Detail;
