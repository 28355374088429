import { useState, useRef, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import CrudService from '../../../services/crudService';

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const Form = ({t}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const query    = useQuery();

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [contactNo, setContact] = useState('');
	const [message, setMessage] = useState('');

	const [errMsg, setErrMsg] = useState('');
	const errRef = useRef();

	console.log('Ref: ' + query.get("ref"));
	console.log('Ref No: ' + query.get("refno"));

	useEffect(() => {
		if (query.get("refno") != null){
			setMessage('Regarding ' + query.get("refno"));
		}
	}, [query])

	const send = async () => {

		try {
            const response = await CrudService.PostData(navigate, location, 'email', 
            {
                first_name: firstName,
				last_name: lastName,
				email: email,
				contact_no: contactNo,
				message: message,
				ref: query.get("ref"),
				ref_no: query.get("refno"),
            });

			setErrMsg('Message was sent Successfully.');
			alert('Message was sent Successfully.');

        } catch (error) {
			alert('Error Occured')
			setErrMsg(error?.response);
            console.error(error); //JSON.stringify(error)
        }

		errRef.current.focus();
	}

	return (
		<form className="contact_form">
			<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} style={{ color: 'red' }} aria-live="assertive">{errMsg}</p>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group">
						<label className="form-label">{ t('contact-us.contact-first-name') }*</label>
						<input
							className="form-control"
							type="text"
							placeholder={ t('contact-us.contact-first-name') }
							name="first_name"
							required
							onChange={e => setFirstName(e.target.value)}
						/>
					</div>
				</div>
				{/* End .col */}

				<div className="col-md-6">
					<div className="form-group">
						<label className="form-label">{ t('contact-us.contact-last-name') }*</label>
						<input
							className="form-control"
							type="text"
							placeholder={ t('contact-us.contact-last-name') }
							name="last_name"
							onChange={e => setLastName(e.target.value)}
						/>
					</div>
				</div>
				{/* End .col */}

				<div className="col-md-6">
					<div className="form-group">
						<label className="form-label">{ t('contact-us.contact-email') }*</label>
						<input
							className="form-control email"
							type="email"
							placeholder={ t('contact-us.contact-email') }
							name="first_name"
							required
							onChange={e => setEmail(e.target.value)}
						/>
					</div>
				</div>
				{/* End .col */}

				<div className="col-md-6">
					<div className="form-group">
						<label className="form-label">{ t('contact-us.contact-phone') }</label>
						<input
							className="form-control"
							type="text"
							placeholder="+81 XXX XX XX"
							name="first_name"
							onChange={e => setContact(e.target.value)}
						/>
					</div>
				</div>
				{/* End .col */}

				<div className="col-sm-12">
					<div className="form-group">
						<label className="form-label">{ t('contact-us.contact-message') }</label>
						<textarea
							name="form_message"
							className="form-control"
							rows={6}
							placeholder={ t('contact-us.contact-message') }
							required
							value={message}
							onChange={e => setMessage(e.target.value)}
						/>
					</div>
					{/* End form-group */}
					<div className="form-group mb0">
						<button type="button" className="btn btn-thm" onClick={e => send()}>
							{ t('contact-us.get-in-touch') }
						</button>
					</div>
				</div>
				{/* End .col */}
			</div>
			{/* End .row */}
		</form>
	);
};

export default Form;
