import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
	//return new URLSearchParams(search);
}

const MainFilter =  ({ handleOutFilter, t }) => {
	console.log('MainFilter Mounted')

	let query = useQuery();
	let navigate = useNavigate();

	const [searchQueries, setSearchQueries] = useState({
		keyword: (query.get("keyword") != null) ? query.get("keyword") : '',
		make: (query.get("make") != null) ? query.get("make") : '',
		bodytype: (query.get("bodytype") != null) ? query.get("bodytype") : '',
		bodytype2: ''
	});
	const [subCatagoriesList, setSubCategoryList] = useState([]);

	const [data, setData] = useState(() => {
		axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/misc/searchdata`)
		.then((res) => {
			console.log(res.data);
			console.log('res.data.data ' + res.data.data);
			setData(res.data.data);
			return res.data.data;
		})
		.catch((err) => {
			console.error(err);
		})
	});
	
	const fetchData = () => {
		console.log('fetchData - Call for Search Data');
		axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/misc/searchdata`)
		.then((res) => {
			console.log(res.data);
			console.log('res.data.data ' + res.data.data);
			setData(res.data.data);
			//return res.data.data;
		})
		.catch((err) => {
			console.error(err);
		})
	}

	//Fetch Sub Cat Codes
    const fetchSubCategory = (e) => {

		let cat_id = (e?.target?.value || e?.target?.value != null) ? e.target?.value : 1;
		//let cat_id =  e.target?.value;

		console.log('bodytype: ' + cat_id);

		if (cat_id || cat_id != '') {
            //CrudService.GetDataById(navigate, locationHook, 'sub-category/category', category)
			axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/sub-category/category/${cat_id}`)
            .then((res) => {
                console.log('Sub Category' + res.data);
                setSubCategoryList(res.data);
            })
			.catch((err) => console.error(err) )
        }

        // if (searchQueries.bodytype || searchQueries.bodytype != '') {
        //     //CrudService.GetDataById(navigate, locationHook, 'sub-category/category', category)
		// 	axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/sub-category/category/${cat_id}`)
        //     .then((res) => {
        //         console.log('Sub Category' + res.data);
        //         setSubCategoryList(res.data);
        //     })
		// 	.catch((err) => console.error(err) )
        // }
    }

	// useEffect(() => {
	// 	fetchSubCategory();
	// }, [searchQueries.bodytype])

	const filterOptions = [
		{
			label: "Condition",
			values: ["Most Recent", "Recent", "Best Selling", "Old Review"],
		},
		{
			label: "Select Makes",
			values: ["Audi", "Bentley", "BMW", "Ford", "Honda", "Mercedes"],
		},
		{
			label: "Select Models",
			values: ["A3 Sportback", "A4", "A6", "Q5"],
		},
		{
			label: "Select Type",
			values: ["Convertible", "Coupe", "Hatchback", "Sedan", "SUV"],
		},
	];

	function handleInputChange(event) {
		event.preventDefault();
        const { name, value } = event.target;

		//setSearchQueries([...searchQueries, {name: name, value: value}]);

		handleOutFilter( name, value );

		query.delete(name);

		query.delete('keyword');
		query.delete('make');

		if (name != 'bodytype2')
			query.delete('bodytype');
		
		//navigate.replace({ search: query.toString() })
		navigate({ search: query.toString() })

		setSearchQueries((prevState) => ({
			...prevState,
			[name]: query.get(name),
		}));

        console.log('Triggered for:' + name);
		console.log('Triggered for:' + value);
    };

	// Get Query Values
	// if (query.get("keyword") != null) {
	// 	console.log('Q title ' + query.get("keyword"));
	// 	handleOutFilter('keyword', query.get("keyword"));
		
	// 	// setSearchQueries((prevState) => ({
	// 	// 	...prevState,
	// 	// 	['keyword']: query.get("keyword"),
	// 	// }));
	// } 

	// if (query.get("make") != null) {
	// 	console.log('Q make ' + query.get("make"));
	// 	handleOutFilter('make', query.get("make"));
	// } 

	// if (query.get("bodytype") != null) {
	// 	console.log('Q bodytype ' + query.get("bodytype"));
	// 	handleOutFilter('bodytype', query.get("bodytype"));
	// } 

	useEffect(() => {

        if (query.get("keyword") != null) {
            console.log('Q title ' + query.get("keyword"));
			handleOutFilter('keyword', query.get("keyword"));
			
			setSearchQueries((prevState) => ({
                ...prevState,
                ['keyword']: query.get("keyword"),
            }));
        } 

        if (query.get("make") != null) {
            console.log('Q make ' + query.get("make"));
			handleOutFilter('make', query.get("make"));
        } 

        if (query.get("bodytype") != null) {
            console.log('Q bodytype ' + query.get("bodytype"));
			handleOutFilter('bodytype', query.get("bodytype"));
        } 

		fetchSubCategory(null);
        
    }, []); //[query]

	return (
		<>
			<div className="col-sm-12 col-lg-2">
				<div className="advance_search_style">
					<input
						className="form-control form_control"
						name="keyword"
						type="text"
						placeholder={t('hero-filter.search-model-and-reference')}
						value={searchQueries.keyword}
						onChange={e => handleInputChange(e)}
						style={{ width: "100%"}}
					/>
				</div>
			</div>
			{/* <div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick" name="condition" onChange={e => handleInputChange(e)}> 
						<option key='0' value={''}>Condition</option>
						<option key='1' value={1}>Used</option>
						<option key='2' value={2}>New</option>
					</select>
				</div>
			</div> */}
			<div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick"  name="bodytype" value={searchQueries.bodytype} onChange={e => {handleInputChange(e); fetchSubCategory(e)}}>
						<option value={''} key={0}>{t('hero-filter.select-category')}</option>
						{data?.categories?.map((value, valueIndex) => (
							<option key={valueIndex} value={value.id} >{value.name}</option>
						))}
					</select>
				</div>
			</div>
			<div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick"  name="bodytype2" value={searchQueries.bodytype2} onChange={e => handleInputChange(e)}>
						<option value={''} key={0}>{t('stock.select-subcategory')}</option>
						{subCatagoriesList?.map((value, valueIndex) => (
							<option key={valueIndex} value={value.id} >{value.name}</option>
							))}
					</select>
				</div>
			</div>
			<div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick" name="make" value={searchQueries.make} onChange={e => handleInputChange(e)}>
						<option value={''} key={0}>{t('hero-filter.select-make')}</option>
						{data?.makes.map((value, valueIndex) => (
							<option key={valueIndex} value={value.id}>{value.name}</option>
						))}
					</select>
				</div>
			</div>
			{/* <div className="col-12 col-sm-4 col-lg-2">
				<div className="advance_search_style">
					<select className="form-select show-tick"  name="model"  onChange={e => handleInputChange(e)}>
						<option value={''} key={0}>Select Model</option>
						{data?.models?.map((value, valueIndex) => (
							<option key={valueIndex} value={value.id} >{value.name}</option>
						))}
					</select>
				</div>
			</div> */}
			{/* {filterOptions.map((option, index) => (
				<div key={index} className="col-12 col-sm-4 col-lg-2">
					<div className="advance_search_style">
						<select className="form-select show-tick">
							<option>{option.label}</option>
							{option.values.map((value, valueIndex) => (
								<option key={valueIndex}>{value}</option>
							))}
						</select>
					</div>
				</div>
			))} */}
		</>
	);
};

export default MainFilter;
