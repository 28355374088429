import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useEffect } from "react";


const Category2 = ({t}) => {
	const navigate = useNavigate();
	const [categoryList, setCategoryList] = useState([]);

	const categories = [
		{
			imgSrc: "/assets/images/category/coupe.png",
			title: t('category.coupe'), //"Coupe",
			value: "Coupe",
			delay: 100,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/wagon.png",
			title: t('category.wagon'), //Wagon
			value: "Wagon",
			delay: 200,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/machinery.png",
			title: t('category.machinery'), //Machinery
			value: "Machinery",
			delay: 300,
			listing: "16,869",
		},
		{
			imgSrc: "/assets/images/category/other.png",
			title: t('category.other-all'), //Other
			value: "Other",
			delay: 400,
			listing: "16,869",
		},
	];

	const handleSearch = async (e, name) => {
		e.preventDefault();

		if (name === "Coupe")
			name = "Coupe";
		else if (name === "Wagon")
			name = "Wagon";
		else if (name === "Machinery")
			name = "Machinery";
		else if (name === "Other")
			name = "Unspecified";
		else
			name = "Unspecified";

		console.log('name : ' + name);
		
		let id_ = 0;


		try {
			const res = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/category/name`, { params: { name: name} });

			if (res)
				id_ = res.data.id;
	
			console.log('id_ ' + id_);
	
			navigate(`${process.env.PUBLIC_URL}/listing?bodytype=${id_}`);

		} catch (error) {
			console.error(error);
		}

    }

	return (
		<>
			{categories.map((category, index) => { return (
				(category.value == "Machinery") ?
					<div
						key={index} //col-6 col-sm-6 col-md-4 col-lg col-xl
						className={`col-6 col-sm-6 col-md-4 col-lg col-xl`}
						data-aos="fade-up"
						data-aos-delay={category.delay}
						data-bs-toggle="modal"
						data-bs-target="#subCatMachModal"
					>
						<div className="category_item home7_style">
							<div className="thumb">
								<img
									width={300} //150
									height={60} //58
									style={{ objectFit: "contain" }}
									src={category.imgSrc}
									alt={`${index + 1}.png`}
								/>
							</div>
							<div className="details">
								<p className="title">
										<a
											href="#"
											data-bs-toggle="modal"
											data-bs-target="#subCatMachModal"
										>
											{category.title}
										</a>
									
									{/* <p className="para">{category.listing} Listings</p> */}
								</p>
							</div>
						</div>
					</div>
					:
					<div
						key={index} //col-6 col-sm-6 col-md-4 col-lg col-xl
						className={`col-6 col-sm-6 col-md-4 col-lg col-xl`}
						data-aos="fade-up"
						data-aos-delay={category.delay}
						onClick={e => handleSearch(e, category.value)}
					>
						<div className="category_item home7_style">
							<div className="thumb">
								<img
									width={300} //150
									height={60} //58
									style={{ objectFit: "contain" }}
									src={category.imgSrc}
									alt={`${index + 1}.png`}
								/>
							</div>
							<div className="details">
								<p className="title">
									<NavLink to="" onClick={e => handleSearch(e, category.value)}>{category.title}</NavLink>
									{/* <p className="para">{category.listing} Listings</p> */}
								</p>
							</div>
						</div>
					</div>
			)})}
		</>
	);
};

export default Category2;
