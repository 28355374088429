import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../../services/crudService'

import Pagination from "../Pagination";
import VehicleContent from "./VehicleContent";

const VehicleListTable = () => {

	const navigate = useNavigate();
    const location = useLocation();
    const [orderList, setOrderList] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [count_, setCount_] = useState(0);
    const handleFilter = () => setShowFilter(!showFilter);

    const [searchQuery, setSearchQuery] = useState([]);
    
    const fetchData = () => {
        console.log('fetchData page:' + page_)
        
        CrudService.GetDataWithParam(navigate, location, 'listing/user', 
        { 
            page: page_,
            order_id: searchQuery.orderId, 
            order_date: searchQuery.orderDate, 
            type: searchQuery.type, 
            status: searchQuery.status
        })
        .then((res) => {
            console.log(res.data);
            setOrderList(res.data?.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setCount_(res.data.count);
        }).catch((error) => {
            console.error(error);
        })
    }

	//Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }
    //---------------------------------------------

    useEffect(() => {
        fetchData();
    }, []);

	useEffect(() => {
        fetchData();
    }, [page_]);

	return (
		<>
			<div className="row">
				
				{/* mt50 */}
				<div className="col-lg-12">
					<div className="tab-content row" id="nav-tabContent">
						<div
							className="tab-pane fade show active"
							id="nav-home"
							role="tabpanel"
							aria-labelledby="nav-home-tab"
						>
							<VehicleContent orderList={orderList} />
						</div>
						{/* End tab-content */}

					</div>
					{/* End tab-content */}

					<div className="mbp_pagination mt10">
						<Pagination pagination={{ currPage: currPage, page_: page_, hasNext: hasNext, hasPrev: hasPrev, count: count_ }} 
                                    setter={{ setCurrPage: setCurrPage, setPage_: setPage_, setHasNext: setHasNext, setHasPrev: setHasPrev, setCount: setCount_ }}/>
					</div>
					{/* Pagination */}
				</div>
			</div>
		</>
	);
};

export default VehicleListTable;
