import { useState, useEffect } from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios';

const HeroFilter = ({t}) => {
	//const router = useRouter();
	const navigate = useNavigate();

	const [selectedStatus, setSelectedStatus] = useState("All Status");
	const [filters, setFilters] = useState([]);
	const [makeList, setMakeList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

	const [searchQueries, setSearchQueries] = useState({
        title: "",
        make: "",
        bodytype: "",
		// refno: "",
		// modelcode: "",
    });

	let filters_ = [];

	useEffect(() => {
		try {
			//Makes List
			axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/make/basic`)
			.then((res) => {
				//console.log(res.data);
				setMakeList(res.data);
			})
	
			//Category List
			axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/category/basic`)
			.then((res) => {
				setCategoryList(res.data);
			})
		} catch (error) {
			console.error(error);
		}

		//console.log(filters_);
		//setFilters(filters_);

		// filters
		// 	//.filter((filter) => filter.label !== selectedStatus)
		// 	.map((item) => {
		// 		console.log('filter :' + item)
		// 		console.log('Label :' + item.label)
		// 		console.log('options :' + item.options)

		// 		filters.options?.map((subItem, index) => {
		// 			console.log('subItem id :' + subItem.id)
		// 			console.log('subItem name :' + subItem.name)
		// 		})
		// 	})

	}, [])

	// useEffect(() => {
	// filters
	// 	//.filter((filter) => filter.label !== selectedStatus)
	// 	.map((filter) => {
	// 		console.log('filter [] :' + filter)
	// 	})
	// }, [filters])


	const filters__ = [
		{
			label: "Make",
			options: [
				"Select Makes",
				"Audi",
				"Bentley",
				"BMW",
				"Ford",
				"Honda",
				"Mercedes",
				"Izuzu",
			],
		},
		{
			label: "Models",
			options: ["Select Models", "A3 Sportback", "A4", "A6", "Q5", "Q6"],
		},
		{
			label: "Price",
			options: [
				"All Price",
				"No max Price",
				"$2,000",
				"$5,000",
				"$10,000",
				"$15,000",
				"$6,000",
			],
		},
	];

	const handleStatusClick = (status) => {
		setSelectedStatus(status);
	};

	const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSearchQueries((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        console.log('Triggered for:' + name);
    };

	const handleSearch = () => {
        navigate(`${process.env.PUBLIC_URL}/listing?keyword=${searchQueries.title}&make=${searchQueries.make}&bodytype=${searchQueries.bodytype}`);
		
		//router.push(`/listing-v1?title=${searchQueries.title}&make=${searchQueries.make}&category=${searchQueries.category}`);
    }

	return (
		<div className="col-lg-12">

			{/* <ul className="nav nav-pills justify-content-center">
				<li className="nav-item" role="presentation">
					<button className={`nav-link ${selectedStatus === "All Status" && "active"}`}
						onClick={() => handleStatusClick("All Status")}
					>
						All Status
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button
						className={`nav-link ${selectedStatus === "Used Cars" && "active"}`}
						onClick={() => handleStatusClick("Used Cars")}
					>
						Used Cars
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button
						className={`nav-link ${selectedStatus === "New Cars" && "active"}`}
						onClick={() => handleStatusClick("New Cars")}
					>
						New Cars
					</button>
				</li>
			</ul> */}

			{/* filter tabs */}
			<div className="adss_bg_stylehome1">
				<div className="home1_advance_search_wrapper">
						<ul className="mb0 text-center">
							<li className="list-inline-item mb10" key={'name'}>
								<div className="select-boxes">
									<div className="car_brand">
										<input className="form-control form_control" type="text" placeholder={t('hero-filter.search-model-and-reference')} name="title" onChange={handleInputChange} />
									</div>
								</div>
							</li>
							<li className="list-inline-item mb10" key={'name'}>
								<div className="select-boxes">
									<div className="car_brand">
										{/* <h6 className="title">Type</h6> */}
										<select className="form-select" name="bodytype" onChange={handleInputChange} >
											<option key="0" value="">{t('hero-filter.select-category')}</option>
											{categoryList.map((option) => (
											<option key={option.id} value={option.id}> {option.name} </option>
											))}
										</select>
									</div>
								</div>
							</li>
							<li className="list-inline-item mb10" key={'makes'}>
								<div className="select-boxes">
									<div className="car_brand">
										{/* <h6 className="title">Makes</h6> */}
										<select className="form-select" name="make" onChange={handleInputChange} >
											<option key="0" value="">{t('hero-filter.select-make')}</option>
											{makeList.map((option) => (
											<option key={option.id} value={option.id}>{option.name}</option>
											))}
										</select>
									</div>
								</div>
							</li>

						{/* Search button */}
						<li className="list-inline-item">
							<div className="d-block">
								<button
									onClick={(e) => handleSearch(e) } //router.push("/listing-v4")
									className="btn btn-thm advnc_search_form_btn"
								>
									<span className="flaticon-magnifiying-glass" />
									{t('hero-filter.search')}
								</button>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default HeroFilter;
