import LoginSignupModal from "../components/common/login-signup";
//import HeaderTop from "../components/home/HeaderTop";
import HeaderTop from "../components/common/HeaderTop";
import HeaderSidebar from "../components/common/HeaderSidebar";
//import Header from "../components/home/Header";
import Header from "../components/home/Header2";
import MobileMenu from "../components/common/MobileMenu";
import WhyChoose from "../components/common/WhyChoose";
import FeaturedFilterListing from "../components/home/FeaturedFilterListing";
import Hero from "../components/home/Hero";
import Hero2 from "../components/home/Hero2";
import Footer from "../components/common/Footer";
//import Category from "../components/home/Category";
import Category1 from "../components/home/Category1";
import Category2 from "../components/home/Category2";
//import PopularListings from "../components/home/PopularListings";
import Counter from "../components/home/Counter";
import HeroFilter from "../components/common/HeroFilter";
import SubCategoryModal from "../components/common/modal/SubCategoryModal";
import SubCatMachineryModal from "../components/common/modal/subCatMachineryModal";

import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

export const metadata = {
    title: "Home || KAGA MOTORS - Used Vehicle Sales and Export.",
    description: `KAGA MOTORS - Used Vehicle Sales and Export. `,
};

const Home = () => {
    const { t } = useTranslation();
    <Helmet>
        <title>KAGA MOTORS - Used Vehicle Sales and Export</title>
        <meta name="description" content="KAGA MOTORS - Used Vehicle Sales and Export" />
        <meta name="theme-color" content="#008f68" />
        <meta property="og:title" content={'KAGA MOTORS - Used Vehicle Sales and Export'} />
        <meta property="og:description" content={'KAGA MOTORS - Used Vehicle Sales and Export'} />
        <meta property="og:image" content={'https://kagamotors.jp/assets/images/logo.png'} />
        <meta property="og:url" content={'https://kagamotors.jp'} />
        <meta property="og:type" content="website" />
    </Helmet>

    return (
        // body_home2_style, wrapper ovh
        <div className="body_home2_style">
            {/* Sidebar Panel Start */}

            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <HeaderSidebar t={t} />
            </div>
            {/* Sidebar Panel End */}

            {/* header top */}
            <HeaderTop t={t} />
            {/* End header top */}

            {/* Main Header Nav */}
            <Header t={t} />
            {/* End Main Header Nav */}

            {/* Main Header Nav For Mobile */}
            <MobileMenu t={t} />
            {/* End Main Header Nav For Mobile */}

            {/* Hero Section */}
            <section className="home-one mt10 mt70-992 p0 bdrs16 bdrs0-md ovh slider_dsk">
                {/* hero_filter_mob */}
                <div className="container-fluid p0 "> 
                    <div className="row ">
                        <div className="col-xl-12">
                            <Hero2 />
                        </div>
                    </div>
                </div>
            </section>
            {/* End Hero Section */}

            {/* Hero Mobile Section */}
            <section className="home-one mt10 mt70-992 p0 bdrs16 bdrs0-md ovh slider_mob">
                {/* hero_filter_mob */}
                <div className="container-fluid p0 "> 
                    <div className="row ">
                        <div className="col-xl-12">
                            <Hero />
                        </div>
                    </div>
                </div>
            </section>
            {/* End Hero Mobile Section */}

            {/* Desktop - filter */}
            <section className="whychose_us pb70 pt0 mt30">
                <div className="container">
                    <div className="row mb20">
                        <div className="col-lg-10 m-auto">
                            <div className="advance_search_panel home2_style">
                                <div className="row">
                                    <HeroFilter t={t} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End .row */}

                    {/* <div className="row mt100 justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-title text-center">
                                <h2>Why Choose Us?</h2>
                            </div>
                        </div>
                    </div> */}
                    {/* End .row */}

                    {/* <div className="row">
                        <WhyChoose />
                    </div> */}
                    {/* End .row */}
                </div>
                {/* End container */}
            </section>
            {/* End How It Works with filter */}

            {/* Car Category */}
            {/* mobile_space  bgc-f9  */}
            <section className="car-category pt0 pb100">
                <div className="container">
                    <div className="row">
                        <Category1 t={t} />
                    </div>
                    <div className="row">
                        <Category2 t={t} />
                    </div>
                </div>
            </section>
            {/* End Car Cartegory */}

            {/* Featured Product  */}
            <section className="featured-product">
                <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                    <div className="main-title text-center">
                        <h2>{ t('featured.our-stock') }</h2>
                        {/* <h2>Our Stock</h2> */}
                    </div>
                    </div>
                </div>
                {/* End .row */}

                <div className="row">
                    <div className="col-lg-12" data-aos-delay="100" data-aos="fade-up">
                    <FeaturedFilterListing t={t} />
                    </div>
                </div>
                {/* End .row */}

                <div className="row mt20">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <NavLink to="/listing" className="more_listing">
                            {/* Show All{" "} */}
                            { t('featured.show-all') }{ " "}
                            <span className="icon">
                                <span className="fas fa-plus" />
                            </span>
                            </NavLink>
                        </div>
                    </div>
                </div>
                {/* End .row */}
                </div>
                {/* End .container */}
            </section>
            {/* End Featured Product  */}

            {/* <!-- Funfact --> */}
            <section className="our-funfact bgc-f9 pt50 pb30">
                <div className="container">
                    <div className="row">
                        <Counter t={t} />
                    </div>
                </div>
            </section>
            {/* <!-- End Funfact --> */}

            {/* Why Chose us  */}
            <section className="why-chose">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-title text-center">
                                {/* <h2>Why Choose Us?</h2> */}
                                <h2>{ t('why-choose.why-choose-us') }</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <WhyChoose t={t} />
                    </div>
                </div>
            </section>
            {/* Why Chose us  */}



            {/* Our Footer */}
            <Footer t={t} />
            {/* End Our Footer */}

            {/* Modal */}
            <div
                className="sign_up_modal modal fade"
                id="logInModal"
                data-backdrop="static"
                data-keyboard=""
                tabIndex={-1}
                aria-hidden="true"
            >
                <LoginSignupModal t={t} />
            </div>
            {/* End Modal */}

            {/* Modal */}
            <div
                className="sign_up_modal modal fade"
                id="subCatModal"
                data-backdrop="static"
                data-keyboard=""
                tabIndex={-1}
                aria-hidden="true"
            >
                <SubCategoryModal t={t} />
            </div>
            {/* End Modal */}

            {/* Modal */}
            <div
                className="sign_up_modal modal fade"
                id="subCatMachModal"
                data-backdrop="static"
                data-keyboard=""
                tabIndex={-1}
                aria-hidden="true"
            >
                <SubCatMachineryModal t={t} />
            </div>
            {/* End Modal */}

        </div>
        // End wrapper
    );
};

export default Home;
