import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

export default function ImageGallery({ data }) {

    if (!data || data == null)
        return <></>

    return (
        <Gallery withCaption>
            {
                data.map((slide, index) => (
                    // <div className='col-xs-3 col-sm-6 col-lg-4 col-xl-4 mb5'>
                    //     <img src={slide.imageSrc} className='img-fluid' />
                    // </div>
                    <Item
                        key={index}
                        original={slide.imageSrc}
                        thumbnail={slide.imageSrc}
                        width="1024"
                        height="768"
                        caption="Kagamotors.jp"
                    >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={slide.imageSrc} className='col-xs-3 col-sm-6 col-lg-4 col-xl-4 mb5' style={{ cursor: 'pointer',  objectFit: 'cover' }} /> //style={{ width: "150px"}}
                        )}
                    </Item>
                ))
            }
        </Gallery>
    )
}