import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import MainFilter from "./MainFilter";
import AdvanceMainFilter from "./AdvanceMainFilter";
import CheckBoxFilter from "./CheckBoxFilter";
import PriceRange from "./PriceRange";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
    //console.log('Search: ' + search);
    return useMemo(() => new URLSearchParams(search), [search]);
}

const AdvanceFilter = ({ handleOutFilter, t }) => {
	//const navigate = useNavigate();
    const location = useLocation();
	const query = useQuery();

	const [searchData, setSearchData] = useState([]);
	
  	const make = query.get('make')
	const title = query.get('title')
	const category = query.get('category')

	const handleSearch = () => {
		//navigate(`/listing-v1?title=${title}&make=${make}&category=${category}`);
    }

	return (
		<>
			<div className="row">
				{/* <MainFilter data={{ makes: makeList, categories: categoryList, models: modelList, fuel: fuelTypeList, trans: transmissionList }} /> */}
				{/* <MainFilter data={{ makes: searchData.makes, categories: searchData.categories, models: searchData.models }} setFilter={props.handleOutFilter} /> */}
				<MainFilter handleOutFilter={handleOutFilter} t={t} />

				<div className="col col-sm-4 col-lg-2">
					<div className="advance_search_style">
						<a
							className="advance_dd_btn d-inline-flex"
							href="#collapseAdvanceSearch"
							data-bs-toggle="collapse"
							role="button"
							aria-expanded={false}
							aria-controls="collapseAdvanceSearch"
						>
							<span className="flaticon-cogwheel" /> {t('stock.advanced')}
						</a>
					</div>
				</div>
				{/* End .col */}

				{/* <div className="col col-sm-4 col-lg-2">
					<div className="advance_search_style">
						<a className="btn search_btn btn-thm" href="" onClick={(e) => handleSearch(e) }>
							<span className="flaticon-magnifiying-glass" /> Search
						</a>
					</div>
				</div> */}
				{/* End .col */}
			</div>
			{/* End .row */}

			<div className="collapse" id="collapseAdvanceSearch">
				<div className="row bgc-thm2">
					<AdvanceMainFilter handleOutFilter={handleOutFilter} t={t} />
				</div>
				{/* End .row */}

				<div className="row">
					<div className="col-sm-6 col-md-4 col-lg-3">
						<div className="advance_search_style">
							<div className="uilayout_range">
								<h6 className="ass_price_title text-white text-start">{t('stock.price')}</h6>
								<PriceRange handleOutFilter={handleOutFilter} t={t} />
							</div>
						</div>
					</div>
					{/* End .col */}

					{/* <div className="col-sm-6 col-md-4 col-lg-6 ">
						<h6 className="font-600 ass_price_title text-white text-start mb-3">
							Features
						</h6>
						<CheckBoxFilter />
					</div> */}
					{/* End .col */}
				</div>
			</div>
		</>
	);
};

export default AdvanceFilter;
