const Social = () => {
	const socialIcons = [
		{
			icon: "fab fa-facebook-f",
			link: "https://facebook.com/kagamotors",
		},
		{
			icon: "fab fa-youtube",
			link: "https://youtube.com/@kagamotors",
		},
		{
			icon: "fab fa-whatsapp",
			link: "https://wa.me/818046319655",
		},
		{
			icon: "fab fa-line",
			link: "https://line.me/ti/p/TlypxaPZEX",
		},
	];

	return (
		<>
			{socialIcons.map((icon, index) => (
				<li className="list-inline-item" key={index}>
					<a href={icon.link} target="_blank">
						<i className={icon.icon} />
					</a>
				</li>
			))}
		</>
	);
};

export default Social;
