import logo from './logo.svg';
//import './App.css';
import { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import "./assets/scss/main.scss";
import { Routes, Route } from "react-router-dom";
import ProtectedLayout from './layout/protectedLayout';
import { CurrencyProvider } from './context/currencyContext'; 

import Home from './pages/Home2';
import Listing from './pages/Listing';
import Detail from './pages/Detail2';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Contact from './pages/Contact';
import About from './pages/About';
import Service from './pages/Service';

import Dashboard from './pages/user/Dashboard';
import ViewOrder from './pages/user/ViewOrder';
import Containers from './pages/user/Containers';
import ViewContainer from './pages/user/ViewContainer';
import Vehicles from './pages/user/Vehicles';

if (typeof window !== "undefined") {
	import("bootstrap");
  }

function App() {
	useEffect(() => {
		Aos.init({
			duration: 1200,
		});
	}, []);

	return (
		<CurrencyProvider>
		<Routes>
				<Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
				<Route path={`${process.env.PUBLIC_URL}/listing`} element={<Listing />} />
				<Route path={`${process.env.PUBLIC_URL}/detail/:id`} element={<Detail />} />

			<Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
			<Route path={`${process.env.PUBLIC_URL}/signup`} element={<Signup />} />

			<Route path={`${process.env.PUBLIC_URL}/contact`} element={<Contact />} />
			<Route path={`${process.env.PUBLIC_URL}/about`} element={<About />} />
			<Route path={`${process.env.PUBLIC_URL}/services`} element={<Service />} />

			{/* Protected */}
			<Route element={<ProtectedLayout />}>
				<Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Dashboard />} />
				<Route path={`${process.env.PUBLIC_URL}/view-order/:id`} element={<ViewOrder />} />

				<Route path={`${process.env.PUBLIC_URL}/containers`} element={<Containers />} />
				<Route path={`${process.env.PUBLIC_URL}/view-container/:id`} element={<ViewContainer />} />

				<Route path={`${process.env.PUBLIC_URL}/vehicles`} element={<Vehicles />} />
			</Route>
			
		</Routes>
		</CurrencyProvider>
	);
}

export default App;
