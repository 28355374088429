import React from 'react';
import { useCurrency } from '../../context/currencyContext';
import { NumericFormat } from 'react-number-format';

function PriceDisplay({ price, rate }) {
    const { currency } = useCurrency();

    var conversionRates = {
        USD: rate,
        JPY: 1, // Example conversion rate
        GBP: 0.72, // Example conversion rate
        // Add more conversion rates here
    };

    const convertedPrice = price * conversionRates[currency];
    //<img src={`./assets/images/item/${(currency === 'USD') ? 'usd2' : 'yen'}.png`} alt="¥" height={15} />
    //return <>{(currency === 'USD') ? '$' : '¥'} {  (currency === 'USD') ? convertedPrice.toFixed(2) : convertedPrice.toFixed(0) }</>
    //return <span>{convertedPrice.toFixed(2)} {currency}</span>;

    return <>{(currency === 'USD') ? '$' : '¥'} <NumericFormat type="text" displayType="text" value={  (currency === 'USD') ? convertedPrice.toFixed(2) : convertedPrice.toFixed(0) } thousandsGroupStyle="thousand" thousandSeparator="," /></>;
}

export default PriceDisplay;
