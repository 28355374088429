import { NavLink, useNavigate } from 'react-router-dom'
import {
    ProSidebarProvider,
    Sidebar,
    Menu,
    MenuItem,
    SubMenu,
} from "react-pro-sidebar";
import { useCurrency } from '../../context/currencyContext';
import i18next from 'i18next';

const MobileMenu = ({t}) => {
    //const path = usePathname();
    const auth = window.localStorage.getItem('user');
    const navigate = useNavigate();

    const logOut = (e) => {
        const auth = window.localStorage.getItem('user');
        //window.localStorage.removeItem('user');
        window.localStorage.setItem('user', '0');
        navigate("/", { replace: true });
    }

    const { currency, setCurrency } = useCurrency();

    var curr_lang = '';
	
	function handleLanguage(lang) {
        i18next.changeLanguage(lang)
        
        curr_lang = i18next.language;
        console.log('curr_lang :' + curr_lang);
    }

	const handleCurrencyChange = (val) => {
		console.log('Curr : ' + val);
		setCurrency(val);
	};

    const socialLinks = [
        {
            name: "Facebook",
			icon: "fab fa-facebook-f",
			link: "https://facebook.com/kagamotors",
		},
		{
            name: "Youtube",
			icon: "fab fa-youtube",
			link: "https://youtube.com/@kagamotors",
		},
		{
            name: "Whatsapp",
			icon: "fab fa-whatsapp",
			link: "https://wa.me/818046319655",
		},
		{
            name: "Line",
			icon: "fab fa-line",
			link: "https://line.me/ti/p/TlypxaPZEX",
		},
    ];

    const contactInfo = [
        {
            icon: "flaticon-map",
            text: "Kawada 639-24, Shimotsuga Gun, Nogi Machi, Tochigi ken, 329-0105 , Japan",
        },
        {
            icon: "flaticon-phone-call",
            text: "+81-280-33-6090",
        },
        {
            icon: "flaticon-clock",
            text: t('header.open-hrs') //"Monday - Friday: 08.30 - 17:00",
        },
    ];

    return (
        <>
            <div className="stylehome1 h0">
                <div className="mobile-menu">
                    <div className="header stylehome1">
                        <div className="mobile_menu_bar">
                            <a
                                className="menubar"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#mobileMenu"
                                aria-controls="mobileMenu"
                            >
                                <small>{t('header.menu')}</small>
                                <span />
                            </a>
                        </div>
                        {/* End mobile_menu_bar */}

                        <div className="mobile_menu_main_logo">
                            <NavLink to="/">
                                <img
                                    width={180}
                                    height={45}
                                    priority
                                    src="../../assets/images/logo.png"
                                    alt="brand"
                                />
                            </NavLink>
                        </div>
                        {/* End .mobile_menu_main_logo */}
                    </div>
                </div>
                {/* /.mobile-menu */}
            </div>
            {/* End mobile menu header */}

            {/* start mobile sidebar menu */}
            <div
                className="offcanvas offcanvas-end mobile_menu-canvas"
                tabIndex="-1"
                id="mobileMenu"
                aria-labelledby="mobileMenuLabel"
                data-bs-scroll="true"
            >
                <div className="offcanvas-body">
                    <div className="pro-header">
                        <NavLink to="/">
                            <img
                                width={180}
                                height={45}
                                priority
                                src="../../assets/images/logo.png"
                                alt="brand"
                            />
                        </NavLink>
                        <div
                            className="fix-icon"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        >
                            <i className="fa-light fa-circle-xmark"></i>
                        </div>
                    </div>
                    {/* End pro-header */}

                    {/* mobile menu items start */}
                    <ProSidebarProvider>
                        <Sidebar
                            width="100%"
                            backgroundColor="#0A2357"
                            className="my-custom-class"
                        >
                            <Menu>
                                <MenuItem key={0}
                                    component={
                                        <NavLink to='/' activeClassName="selected" className="" />
                                    }
                                >
                                    {t('header.home')}
                                </MenuItem>
                                <MenuItem key={1}
                                    component={
                                        <NavLink to='/listing' activeClassName="selected" className="" />
                                    }
                                >
                                    {t('header.stock')}
                                </MenuItem>
                                <MenuItem key={2}
                                    component={
                                        <NavLink to='/services' activeClassName="selected" className="" />
                                    }
                                >
                                    {t('header.services')}
                                </MenuItem>
                                <MenuItem key={3}
                                    component={
                                        <NavLink to="/about" activeClassName="selected" className="" />
                                    }
                                >
                                    {t('header.about-us')}
                                </MenuItem>
                                <MenuItem key={4}
                                    component={
                                        <NavLink to="/contact" activeClassName="selected" className="" />
                                    }
                                >
                                    {t('header.contact-us')}
                                </MenuItem>

                                <SubMenu key={5}
                                    label={t('header.currency')}
                                    className=""
                                >
                                    <MenuItem key={0}
                                        component={
                                            <NavLink to="#" className="" />
                                        }
                                        role="button" onClick={() => handleCurrencyChange('USD')}
                                    >
                                        <img src="../../assets/images/header/usd.png" width={32} /> USD
                                    </MenuItem>
                                    <MenuItem key={1}
                                        component={
                                            <NavLink to="#" className="" />
                                        }
                                        role="button" onClick={() => handleCurrencyChange('JPY')}
                                    >
                                        <img src="../../assets/images/header/jpy.png" width={32} /> JPY
                                    </MenuItem>
                                </SubMenu>

                                <SubMenu key={6}
                                    label={t('header.language')}
                                    className=""
                                >
                                    <MenuItem key={0}
                                        component={
                                            <NavLink to="#" className="" />
                                        }
                                        role="button" onClick={() => handleLanguage('en')}
                                    >
                                        <img src="../../assets/images/header/en.png" width={'34px'}/> English
                                    </MenuItem>
                                    <MenuItem key={1}
                                        component={
                                            <NavLink to="#" className="" />
                                        }
                                        role="button" onClick={() => handleLanguage('jp')}
                                    >
                                        <img src="../../assets/images/header/jp.png" width={'34px'}/> 日本語
                                    </MenuItem>
                                </SubMenu>

                                { (auth == '0' || auth == '' || auth == null) ?
								<>  
                                    <MenuItem key={5}
                                        component={
                                            <NavLink to="#" className="" data-bs-toggle="modal" data-bs-target="#logInModal"/>
                                        }
                                    >
                                        {t('header.login')}
                                    </MenuItem>
                                    <MenuItem key={6}
                                        component={
                                            <NavLink to="#" className="" data-bs-toggle="modal" data-bs-target="#logInModal"/>
                                        }
                                    >
                                        {t('header.register')}
                                    </MenuItem>
								</>
								:
								<>  
                                    <MenuItem key={5}
                                        component={
                                            <NavLink to="/dashboard" className=""/>
                                        }
                                    >
                                        {t('header.dashboard')}
                                    </MenuItem>
                                    <MenuItem key={6}
                                        component={
                                            <NavLink to="/" className="" onClick={(e) => logOut(e)} />
                                        }
                                    >
                                        {t('header.logout')}
                                    </MenuItem>

								</>
								}

                                {/* <li className="dropitem" key={1}>
                                    <a class="" href="#">
                                        <span class="title">$</span>
                                        <span class="arrow"></span>
                                    </a>
                                    <ul class="sub-menu">
                                        <li class="dropitem">
                                            <a class="" href="#"><img src="./assets/images/header/usd.png" width={32} /> - USD</a>
                                            <a class="" href="#"><img src="./assets/images/header/jpy.png" width={32} /> - JPY</a>
                                        </li>
                                    </ul>
                                </li> */}
                                {/* -- */}
                            </Menu>
                            
                            {/* <Menu>
                                {menuItems.map((item, index) => (
                                    <>
                                        {item.subMenu ? (
                                            <SubMenu
                                                key={index}
                                                className={
                                                    isParentActive(item.subMenu, path)
                                                        ? "active"
                                                        : ""
                                                }
                                                label={item.label}
                                            >
                                                {item.subMenu?.map((subItem, subIndex) =>
                                                    subItem.subMenu ? (
                                                        <SubMenu
                                                            key={subIndex}
                                                            label={subItem.label}
                                                            className={
                                                                isParentActive(
                                                                    subItem.subMenu,
                                                                    path
                                                                )
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                        >
                                                            {subItem.subMenu.map(
                                                                (
                                                                    nestedItem,
                                                                    nestedIndex
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            nestedIndex
                                                                        }
                                                                        component={
                                                                            <Link
                                                                                className={
                                                                                    nestedItem.path ==
                                                                                    path
                                                                                        ? "active"
                                                                                        : ""
                                                                                }
                                                                                href={
                                                                                    nestedItem.path
                                                                                }
                                                                            />
                                                                        }
                                                                    >
                                                                        {
                                                                            nestedItem.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </SubMenu>
                                                    ) : (
                                                        <MenuItem
                                                            key={subIndex}
                                                            component={
                                                                <Link
                                                                    className={
                                                                        subItem.path ==
                                                                        path
                                                                            ? "active"
                                                                            : ""
                                                                    }
                                                                    href={subItem.path}
                                                                />
                                                            }
                                                        >
                                                            {subItem.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </SubMenu>
                                        ) : (
                                            <MenuItem
                                                key={index}
                                                component={
                                                    <Link
                                                        className={
                                                            item.path ==
                                                            path
                                                                ? "active"
                                                                : ""
                                                        }
                                                        href={item.path}
                                                    />
                                                }
                                            >
                                                {item.label}
                                            </MenuItem>
                                        )}
                                    </>
                                ))}
                            </Menu> */}
                        </Sidebar>
                    </ProSidebarProvider>
                    {/* mobile menu items end */}

                    <div className="pro-footer mm-add-listing">
                        <div className="border-none">
                            <div className="mmenu-contact-info">
                                {contactInfo.map((info, index) => (
                                    <span className="phone-num" key={index}>
                                        <i className={info.icon} />{" "}
                                        <a href="#">{info.text}</a>
                                    </span>
                                ))}
                            </div>

                            <div className="social-links">
                                {socialLinks.map((link, index) => (
                                    <a href={link.link} key={index}>
                                        <span className={link.icon} />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End mm-add-listng */}
                </div>
                {/* End offcanvas-body */}
            </div>
            {/* End mobile sidebar menu */}
        </>
    );
};

export default MobileMenu;
