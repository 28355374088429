import React, { useRef, useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'
import { useLocalStorage } from "../../../hooks/useLocalStorage";

const Form = ({t}) => {
	const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [userLocal, setUserLocal] = useLocalStorage("user", null);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

	const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/api/auth/login`,
                {
                    email: user,
                    password: pwd
                },
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': "*" 
                    },
                    // withCredentials: true
                }
            );

            console.log(JSON.stringify(response?.data));

            const accessToken = response?.data?.token;
            //const roles = [5150]; //response?.data?.roles;
            let roles = [];
            roles.push(response?.data?.permission_set);
            const userId = response?.data?.id;

            console.log('accessToken:' + accessToken);

            setUserLocal({ user, roles, accessToken, userId });
            setUser('');
            setPwd('');

            navigate(from, { replace: true });

        } catch (err) {
            console.log('Login Err:' + err);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

	return (
		<form onSubmit={handleSubmit} method='post'>
			<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
			<div className="mb-2 mr-sm-2">
				<label className="form-label">{ t('contact-us.contact-email') } *</label>
				<input
					type="text"
					className="form-control"
					placeholder="Email"
					required
					ref={userRef} autoComplete="off"
					onChange={(e) => setUser(e.target.value)}
					value={user} 
				/>
			</div>
			{/* End div */}

			<div className="form-group mb5">
				<label className="form-label">{ t('form.password') } *</label>
				<input
					type="password"
					className="form-control"
					placeholder="Password"
					required  autoComplete="off"
					name="password"
					onChange={(e) => setPwd(e.target.value)}
					value={pwd}
				/>
			</div>
			{/* End div */}

			{/* <div className="custom-control custom-checkbox">
				<input
					type="checkbox"
					className="custom-control-input"
					id="exampleCheck3"
					required
				/>
				<label className="custom-control-label" htmlFor="exampleCheck3">
					Remember me
				</label>
				<a className="btn-fpswd float-end" href="#">
					Lost your password?
				</a>
			</div> */}
			{/* End div */}

			<button type="submit" className="btn btn-log btn-thm mt5">
				Sign in
			</button>
		</form>
	);
};

export default Form;
