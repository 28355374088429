import { NavLink } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

const ContainerContent = ({ orderList }) => {
	return (
		<div className="col-lg-12">
			<div className="table-responsive my_lisging_table">
				<table className="table">
					<thead className="table-light">
						<tr className="thead_row">
							<th className="thead_title pl20" scope="col">
								#
							</th>
                            <th className="thead_title" scope="col">
								Container No
							</th>
							<th className="thead_title" scope="col">
								Reference
							</th>
							<th className="thead_title" scope="col">
								Date
							</th>
                            <th className="thead_title" scope="col">
								Invoice No
							</th>
							<th className="thead_title" scope="col">
								Description
							</th>
							<th className="thead_title" scope="col">
								Delivery Status
							</th>
							<th className="thead_title" scope="col">
								
							</th>
						</tr>
					</thead>
					{/* End thead */}

					<tbody>
						{orderList?.map((value) => {
							let utcDate = value.order_date;
							let localDate = new Date(utcDate);
							
							return (
								<tr key={value.id}>
									<td class="align-middle" align="center">
										<NavLink to={`/view-container/${value.id}`} className={'text-primary'}>#{ value.id }</NavLink>
									</td>
                                    <td className="align-middle">{ value.container_no }</td>
									<td className="align-middle">{ value.container_ref }</td>
									<td className="align-middle">{ localDate.toLocaleDateString() }</td>
                                    <td className="align-middle">{ value.invoice_no }</td>
                                    <td className="align-middle">{ value.remark }</td>
									<td className="align-middle">{ (value.status == 1) && <span class="badge bg-primary">Preparing</span> || (value.status == 2) && <span class="badge bg-warning">In-Transit</span> || <span class="badge bg-success">Completed</span> }</td>
									
									
									<td className="editing_list align-middle">
										<ul>
											<li className="list-inline-item mb-1">
												<NavLink exact to={`/view-container/${value.id}`} className={'text-primary'} title="View"><span className="flaticon-view"></span></NavLink>
											</li>
										</ul>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ContainerContent;
