import Footer from "../components/common/Footer";
import HeaderTop from "../components/common/HeaderTop";
import HeaderSidebar from "../components/common/HeaderSidebar";
import DefaultHeader from "../components/common/DefaultHeader";
import MobileMenu from "../components/common/MobileMenu";
import LoginSignupModal from "../components/common/login-signup";
import Form from "../components/pages/login/Form";
//import SocialLogin from "@/app/components/pages/login/SocialLogin";
import { NavLink } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

export const metadata = {
	title: "LogIn || Voiture - Automotive & Car Dealer NextJS Template",
};

const LogIn = () => {
	const { t } = useTranslation();
	<Helmet>
        <title>Login || KAGA Motors - Automotive & Car Dealer in Japan</title>
        <meta name="description" content="Customer Login in KAGA Motors - Automotive & Car Dealer in Japan." />
        <meta name="theme-color" content="#008f68" />
		<meta property="og:title" content={'Login || KAGA Motors - Automotive & Car Dealer'} />
        <meta property="og:description" content={'Customer Login in KAGA Motors - Automotive & Car Dealer in Japan.'} />
        <meta property="og:image" content={'https://kagamotors.jp/assets/images/logo.png'} />
        <meta property="og:url" content={'https://kagamotors.jp/login'} />
        <meta property="og:type" content="website" />
    </Helmet>

	return (
		<div className="wrapper">
			<div
				className="offcanvas offcanvas-end"
				tabIndex="-1"
				id="offcanvasRight"
				aria-labelledby="offcanvasRightLabel"
			>
				<HeaderSidebar t={t} />
			</div>
			{/* Sidebar Panel End */}

			{/* header top */}
			<HeaderTop t={t} />
			{/* End header top */}

			{/* Main Header Nav */}
			<DefaultHeader t={t} />
			{/* End Main Header Nav */}

			{/* Main Header Nav For Mobile */}
			<MobileMenu t={t} />
			{/* End Main Header Nav For Mobile */}

			{/*  SigIn */}
			<section className="our-log bgc-f9">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-lg-6 col-xl-5">
							<div className="login_form mt60-sm">
								<h2 className="title">{ t('form.log-in') }</h2>
								<p>
									New to Kagamotors.com? <NavLink to="/signup">Sign up.</NavLink>
								</p>
								<Form t={t} />
							</div>
						</div>
						{/* End .col */}

						{/* <div className="col-md-6 col-lg-5 offset-xl-1 col-xl-5 offset-xl-2">
							<div className="login_with">
								<h2 className="title">Connect With Social</h2>
								<SocialLogin />
							</div>
						</div> */}
						{/* End .col */}
					</div>
					{/* End .row */}
				</div>
				{/* End .container */}
			</section>
			{/*  SigIn */}

			{/* Our Footer */}
			<Footer t={t} />
			{/* End Our Footer */}

			{/* Modal */}
			<div
				className="sign_up_modal modal fade"
				id="logInModal"
				data-backdrop="static"
				data-keyboard="false"
				tabIndex={-1}
				aria-hidden="true"
			>
				<LoginSignupModal />
			</div>
			{/* End Modal */}
		</div>
		// End wrapper
	);
};

export default LogIn;
