const ListWithImage = ({t}) => {
    const serviceItems = [
        { text: t('services.vehicle-inspection-service'), link: "#" }, // "Vehicle Inspection & Price Evaluation"
        { text: t('services.towtruck-service') , link: "#" }, //"Tow Truck Service"
        { text: t('services.shipping-services') , link: "#" }, //"Shipping Services"
        { text: t('services.vehicle-dismantling') , link: "#" }, //"Vehicle Dismantling"
        { text: t('services.container-vanning') , link: "#" }, //"Container Vanning"
    ];

    return (
        <div className="row">
            <div className="col-lg-6 col-xl-5">
                <div className="service_include">
                    <h3 className="title">{ t('services.our-services-include') }</h3>
                    <p className="para">
                        { t('services.services-desc') } 
                        {/* Our vehicle experts inspect vehicles over 200
                        checkpoints so you can get a complete satisfaction before purchasing. */}
                    </p>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="si_list">
                                <ul className="mb0 order_list list-style-check-circle check_theme_color">
                                    {serviceItems
                                        .slice(0, 5)
                                        .map((item, index) => (
                                            <li key={index}>
                                                <a href={item.link}>
                                                    {item.text}
                                                </a>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                        {/* End .col */}

                        <div className="col-lg-5">
                            <div className="si_list">
                                <ul className="mb0 order_list list-style-check-circle check_theme_color">
                                    {serviceItems
                                        .slice(5, 10)
                                        .map((item, index) => (
                                            <li key={index}>
                                                <a href={item.link}>
                                                    {item.text}
                                                </a>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                        {/* End .col */}
                    </div>
                    {/* End .row */}
                </div>
                {/* End service_include */}
            </div>
            {/* End col-6 */}

            <div className="col-lg-6 col-xl-6 offset-xl-1">
                <div className="service_thumb service_mob">
                    <img
                        width={636}
                        height={667}
                        layout="responsive"
                        src= "./assets/images/about/about1.png" //"./assets/images/service/1.jpg"
                        alt="1.jpg"
                    />
                </div>
            </div>
            {/* End col-6 */}
        </div>
    );
};

export default ListWithImage;
