import { NavLink, useNavigate } from 'react-router-dom'

const DashboardHeaderTop = ({t}) => {
	const auth = window.localStorage.getItem('user');
	const navigate = useNavigate();

	const logOut = (e) => {
        //window.localStorage.removeItem('user');
        window.localStorage.setItem('user', '0');
        navigate("/");
    }

	const socialData = [
		{
			icon: "fab fa-facebook-f",
			link: "#",
		},
		{
            icon: "fab fa-youtube",
            link: "#",
        },
        {
            icon: "fab fa-whatsapp",
            link: "#",
        },
        {
            icon: "fab fa-line",
            link: "#",
        },
	];

	const contactData = [
		{
            icon: "flaticon-phone-call",
            text: "+81-280-33-6090",
        },
        // {
        //     icon: "flaticon-map",
        //     text: "Kawada 639-24, Shimotsuga Gun, Nogi Machi, Tochigi ken, 329-0105",
        // },
        {
            icon: "flaticon-clock",
            text: "Mon - Sat 8:30 - 18:00",
        },
	];

	return (
		<div className="header_top home3_style dashbord_style dn-992">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-8 col-xl-7">
						<div className="header_top_contact_opening_widget text-center text-md-start">
							<ul className="mb0">
								{contactData.map((contact, index) => (
									<li className="list-inline-item" key={index}>
										<a href="#">
											<span className={contact.icon} />
											{contact.text}
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
					{/* End .col */}

					<div className="col-lg-4 col-xl-5">
						<div className="header_top_social_widgets text-center text-md-end">
							<ul className="m0">
								{socialData.map((social, index) => (
									<li className="list-inline-item" key={index}>
										<a href={social.link} target='_blank'>
											<span className={social.icon} />
										</a>
									</li>
								))}
								{ (auth == 0 || auth == null || auth == '' || !auth) ?
								<>
									<li className="list-inline-item">
										<a
											href="#"
											data-bs-toggle="modal"
											data-bs-target="#logInModal"
										>
											Login
										</a>
									</li>
									<li className="list-inline-item">
										<a
											href="#"
											data-bs-toggle="modal"
											data-bs-target="#logInModal"
										>
											Register
										</a>
									</li>
								</>
								:
								<>
									<li className="list-inline-item">
										<NavLink
											exact
											to="/dashboard"
										>
											Dashboard
										</NavLink>
									</li>
									<li className="list-inline-item">
										<NavLink
											exact
											to="/"
											onClick={(e) => logOut(e)}
										>
											Logout
										</NavLink>
									</li>
								</>
								}
							</ul>
						</div>
					</div>
					{/* End .col */}
				</div>
				{/* End .row */}
			</div>
			{/* End .container */}
		</div>
	);
};

export default DashboardHeaderTop;
