import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'

const DashboardSidebarMenu = () => {

    const menuItems = [
        // {
        //     icon: "flaticon-dashboard",
        //     label: "Dashboard",
        //     path: "/dashboard",
        // },
        {
            icon: "flaticon-list",
            label: "My Orders",
            path: "/dashboard",
        },
        {
            icon: "flaticon-list",
            label: "My Containers",
            path: "/containers",
        },
        {
            icon: "flaticon-list",
            label: "My Vehicles",
            path: "/vehicles",
        },
        // {
        //     icon: "flaticon-user-2",
        //     label: "Profile",
        //     path: "/profile",
        // },
        // {
        //     icon: "flaticon-heart",
        //     label: "Favorites",
        //     path: "/favourites",
        // },
        // {
        //     icon: "flaticon-plus",
        //     label: "Add Listing",
        //     path: "/add-listings",
        // },
        // {
        //     icon: "flaticon-message",
        //     label: "Messages",
        //     path: "/messages",
        // },
        // {
        //     icon: "flaticon-logout",
        //     label: "Logout",
        //     path: "/login",
        // },
    ];

    const userLocal = JSON.parse(window.localStorage.getItem('user'));

    const navigate = useNavigate();

    const logOut = (e) => {
        e.preventDefault();

        console.log('In logOut')
        //window.localStorage.removeItem('user');
        window.localStorage.setItem('user', '0');
        navigate("/", { replace: true });
    };

    return (
        <>  
            {menuItems.map((item, index) => (
                <li key={index}>
                    <NavLink
                        className=""
                        activeClassName="selected" 
                        to={item.path}
                    >
                        <span className={item.icon} />
                        {item.label}
                    </NavLink>
                </li>
            ))}
            <li key={2}>
                <NavLink
                    className=""
                    activeClassName="selected" 
                    to={'/logout'}
                    onClick={e => logOut(e)}
                >
                    <span className="flaticon-logout" />
                    Logout
                </NavLink>
            </li>
        </>
    );
};

export default DashboardSidebarMenu;
