const Overview = ({ listingList, t }) => {

	if (!listingList || listingList == null || listingList == '')
		return <></>

	const carData = [
		{ label: t('stock.category'), value: listingList.Category?.name },
		// { label: "Sub Category", value: listingList.SubCategory.name },
		{ label: t('stock.make'), value: listingList.Make?.name },
		{ label: t('stock.model'), value: listingList.model_code }, //listingList.Model?.name
		{ label: t('stock.VIN'), value: (listingList.vin).slice(0, 6) + '*****' },
		{ label: t('stock.registration-year'), value: listingList.r_year + '/' + listingList.r_month},
		{ label: t('stock.manufacture-year'), value: listingList.m_year + '/' + listingList.m_month},
		{ label: t('stock.mileage'), value: listingList.mileage + "km" },
		{ label: t('stock.transmission'), value: listingList.TransmissionType ? listingList.TransmissionType?.name : listingList.transmission_type_id },
		{ label: t('stock.drive-type'), value: listingList.drive_type },
		{ label: t('stock.fuel-type'), value: listingList.FuelType ? listingList.FuelType?.name : listingList.fuel_type_id },
		{ label: t('stock.displacement'), value: listingList.displacement + "cc" },
		{ label: t('stock.doors'), value: listingList.doors },
		{ label: t('stock.passenger'), value: listingList.no_of_passengers }, 
		{ label: t('stock.colour'), value: listingList.color_e },
		{ label: t('stock.dimensions'), value: (listingList.length/1000).toFixed(2) + ' x ' + (listingList.width/1000).toFixed(2) + ' x ' + (listingList.height/1000).toFixed(2) },
		{ label: "M3", value: ((listingList.length/1000) * (listingList.width/1000) * (listingList.height/1000)).toFixed(2) + 'm3' },
		//{ label: "Condition", value: listingList.Make.name },
	];

	return (
		<ul className="list-group">
			{carData.map((item, index) => (
				<li
					className="list-group-item d-flex justify-content-between align-items-start"
					key={index}
				>
					<div className="me-auto">
						<div className="day">{item.label}</div>
					</div>
					<span className="schedule">{item.value}</span>
				</li>
			))}
		</ul>
	);
};

export default Overview;
