import { useEffect } from "react";
import { useState } from "react";

const Pagination = ({ pagination, setter }) => {

	const [currentPage, setCurrentPage] = useState((pagination?.currPage) ? pagination.currPage : 1);
	const totalPages = (pagination?.count % 12 == 0) ? pagination?.count / 12 : (pagination?.count / 12) + 1 ; //(pagination?.count) ? (pagination?.count <= 3) ? pagination.count : 3 : 1;
	const pages = [];
	console.log('totalPages :' + totalPages);
	console.log('pagination?.count :' + pagination?.count);

	const handleClick = (page) => {
		setCurrentPage(page);
		setter.setPage_(parseInt(page))
		console.log('currentPage :' + currentPage)
		console.log('pagination?.hasNext :' + pagination?.hasNext)
	};

	//Next Page
    const nextPage = () => {
		console.log('pagination?.hasNext :' + pagination?.hasNext)
		console.log('currentPage :' + currentPage)
        if (pagination?.hasNext)
			setCurrentPage(currentPage + parseInt('1'));
			setter.setPage_(currentPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
		console.log('pagination?.hasPrev :' + pagination?.hasPrev)
        if (pagination?.hasPrev)
			setCurrentPage(currentPage + parseInt('1'));
			setter.setPage_(currentPage - parseInt('1'));
    }

	for (let i = 1; i <= totalPages; i++) {
		
		pages.push(
			<li
				role="button"
				key={i}
				className={`page-item ${i === currentPage ? "active" : ""}`}
				onClick={() => handleClick(i)}
			>
				<span className="page-link">{i}</span>
			</li>
		);
	}

	useEffect(() => {

	})

	return (
		<ul className="page_navigation">
			<li role="button" className="page-item" onClick={() => prevPage()} >
				<span className="page-link">
					<span className="fa fa-arrow-left" />
				</span>
			</li>
			{pages}
			<li role="button" className="page-item" onClick={() => nextPage()} aria-readonly>
				<span className="page-link">
					<span className="fa fa-arrow-right" />
				</span>
			</li>
		</ul>
	);
};

export default Pagination;
