import Footer from "../components/common/Footer";
import HeaderTop from "../components/common/HeaderTop";
import HeaderSidebar from "../components/common/HeaderSidebar";
import DefaultHeader from "../components/common/DefaultHeader";
import MobileMenu from "../components/common/MobileMenu";

import LoginSignupModal from "../components/common/login-signup";
import AdvanceFilter from "../components/listing/advance-filter/index";
import Pagination from "../components/common/Pagination";
import ListGridFilter from "../components/listing/ListGridFilter";
import CarItems from "../components/listing/listing-styles/listing-v1/CarItems";
//import axios from 'axios';
import { useState, useEffect, useMemo } from "react";
import { useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

export const metadata = {
    title: "Listing || KAGA Motors - Automotive & Car Dealer in Japan",
};

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const Listing = (req) => {
    const { t } = useTranslation();
    <Helmet>
        <title>Stocks || KAGA Motors - Automotive & Car Dealer in Japan</title>
        <meta name="description" content="Vehicle Stock in KAGA Motors - Automotive & Car Dealer in Japan." />
        <meta name="theme-color" content="#008f68" />
        <meta property="og:title" content={'Stocks || KAGA Motors - Automotive & Car Dealer in Japan'} />
        <meta property="og:description" content={'Vehicle Stock in KAGA Motors - Automotive & Car Dealer in Japan.'} />
        <meta property="og:image" content={'https://kagamotors.jp/assets/images/logo.png'} />
        <meta property="og:url" content={'https://kagamotors.jp/listing'} />
        <meta property="og:type" content="website" />
    </Helmet>

    let query = useQuery();
    const [outFilter, setOutFilter] = useState({
		keyword: (query.get("keyword") != null) ? query.get("keyword") : '',
        //refno: (query.get("refno") != null) ? query.get("refno") : '',
        //modelcode: (query.get("modelcode") != null) ? query.get("modelcode") : '',
		make: (query.get("make") != null) ? query.get("make") : '',
		bodytype: (query.get("bodytype") != null) ? query.get("bodytype") : '',
        bodytype2: (query.get("bodytype2") != null) ? query.get("bodytype2") : '',
	});

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [count, setCount] = useState(0);


    const handleOutFilter = (name, value, obj = null) => {
        console.log('In Page handleOutFilter:' + value);
        if (value != null) {
            console.log('In Page handleOutFilter -:' + value);
            //setOutFilter(...outFilter, value);

            if (query.get("keyword") == null){
                setOutFilter((prevState) => ({
                    ...prevState,
                    ['keyword']: '',
                }));
            }

            if (query.get("make") == null){
                setOutFilter((prevState) => ({
                    ...prevState,
                    ['make']: '',
                }));
            }

            if (query.get("bodytype") == null){
                setOutFilter((prevState) => ({
                    ...prevState,
                    ['bodytype']: '',
                }));
            }

            if (query.get("bodytype2") == null){
                setOutFilter((prevState) => ({
                    ...prevState,
                    ['bodytype2']: '',
                }));
            }

            setOutFilter((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            
            console.log('In Page handleOutFilter -:' + value.name);
        }else if(obj != null){
            
            setOutFilter((prevState) => ({
                ...prevState,
                ['minprice']: obj.min,
            }));

            setOutFilter((prevState) => ({
                ...prevState,
                ['maxprice']: obj.max,
            }));
        }
    }

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    // useEffect(() => {

    //     if (query.get("keyword") != null) {
    //         console.log('Q keyword ' + query.get("keyword"));
    //         setOutFilter((prevState) => ({
    //             ...prevState,
    //             ['keyword']: query.get("keyword"),
    //         }));
    //     } else{
    //         setOutFilter((prevState) => ({
    //             ...prevState,
    //             ['keyword']: '',
    //         }));
    //     } 

    //     if (query.get("make") != null) {
    //         console.log('Q make ' + query.get("make"));
    //         setOutFilter((prevState) => ({
    //             ...prevState,
    //             ['make']: query.get("make"),
    //         }));
    //     } else{
    //         setOutFilter((prevState) => ({
    //             ...prevState,
    //             ['make']: '',
    //         }));
    //     }  

    //     if (query.get("bodytype") != null) {
    //         console.log('Q make ' + query.get("bodytype"));
    //         setOutFilter((prevState) => ({
    //             ...prevState,
    //             bodytype: query.get("bodytype"),
    //         }));
    //     } else{
    //         setOutFilter((prevState) => ({
    //             ...prevState,
    //             ['bodytype']: '',
    //         }));
    //     }  
        
    // }, [query]);
    
    return (
        <div className="wrapper">
            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <HeaderSidebar t={t} />
            </div>
            {/* Sidebar Panel End */}

            {/* header top */}
            <HeaderTop t={t} />
            {/* End header top */}

            {/* Main Header Nav */}
            <DefaultHeader t={t} />
            {/* End Main Header Nav */}

            {/* Main Header Nav For Mobile */}
            <MobileMenu t={t} />
            {/* End Main Header Nav For Mobile */}

            {/* Advance_search_menu_sectn*/}
            <section className="advance_search_menu_sectn bgc-thm2 pt20 pb0 mt70-992 filter-style_two">
                <div className="container">
                    <AdvanceFilter handleOutFilter={handleOutFilter}  t={t} />
                </div>
            </section>
            {/* End Advance_search_menu_sectn*/}

            {/* Inner Page Breadcrumb */}
            <section className="inner_page_breadcrumb style2 inner_page_section_spacing">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="breadcrumb_content style2">
                                <h2 className="breadcrumb_title">
                                    { t('featured.our-stock') }
                                </h2>
                                <p className="subtitle">{t('header.stock')}</p>
                                <ol className="breadcrumb fn-sm mt15-sm">
                                    <li className="breadcrumb-item">
                                        <a href="#">{t('header.home')}</a>
                                    </li>
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        {t('header.stock')}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Inner Page Breadcrumb */}

            {/* Listing Grid View */}
            <section className="our-listing pt0 bgc-f9 pb30-991">
                <div className="container">
                    <div className="row">
                        <ListGridFilter count={count}  t={t} />
                    </div>
                    {/* End .row */}

                    <div className="row">
                        <CarItems 
                            pagination={{ currPage: currPage, page_: page_, hasNext: hasNext, hasPrev: hasPrev, count: count }} 
                            setter={{ setCurrPage: setCurrPage, setPage_: setPage_, setHasNext: setHasNext, setHasPrev: setHasPrev, setCount: setCount }}
                            filter={outFilter} 
                        />
                    </div>
                    {/* End .row */}

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mbp_pagination mt10">
                                <Pagination 
                                    pagination={{ currPage: currPage, page_: page_, hasNext: hasNext, hasPrev: hasPrev, count: count }} 
                                    setter={{ setCurrPage: setCurrPage, setPage_: setPage_, setHasNext: setHasNext, setHasPrev: setHasPrev, setCount: setCount }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* End .row */}
                </div>
            </section>
            {/* Listing Grid View */}

            {/* Our Footer */}
            <Footer  t={t} />
            {/* End Our Footer */}

            {/* Modal */}
            <div
                className="sign_up_modal modal fade"
                id="logInModal"
                data-backdrop="static"
                data-keyboard=""
                tabIndex={-1}
                aria-hidden="true"
            >
                <LoginSignupModal />
            </div>
            {/* End Modal */}
        </div>
        // End wrapper
    );
};

export default Listing;
