"use client";
import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import axios from 'axios';
import { useInView } from "react-intersection-observer";

const Counter = ({t}) => {
	const [totalSales, setTotalSales] = useState(fetchData());
	const [vendorCount, setVendorCount] = useState(fetchData());

	function fetchData() {
		console.log('fetchData')
        //Featured Listing List
        axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/stats/counter`)
        .then((res) => {
            //console.log(res.data);
            setTotalSales(res.data.data.total_sales);
			setVendorCount(res.data.data.vendor_count);
        })
        .catch((err) => {
            console.error(err);
        })
    };

	console.log('Total Sales : ' || totalSales);
	var counters = [
		{ number: parseInt(21344) + parseInt(totalSales), label: t('count.vehicle-sales'), delay: 100 },
		{ number: 2242 + parseInt(vendorCount), label: t('count.happy-customers'), delay: 200 },
		{ number: 20, label: t('count.years'), delay: 300 },
		// { number: 55, label: t('count.countries-export'), delay: 400 },
	];

	return (
		<>
			{counters.map((counter, index) => (
				<div
					key={index}
					className="col-sm-6 col-lg-4"
					data-aos="fade-up"
					data-wow-delay={counter.delay}
				>
					<div className="funfact_one text-center">
						<div className="details">
							<div className="timer">
								<CounterWithAnimation end={counter.number} />
							</div>
							<p className="para">{counter.label}</p>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

const CounterWithAnimation = ({ end }) => {
	const countRef = useRef(null);
	const [inView, setInView] = useState(false);

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.1,
		};

		const observer = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setInView(true);
			}
		}, options);

		const currentRef = countRef.current; // Create a local variable

		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, []);

	return (
		<span ref={countRef}>
			{inView ? <CountUp end={end} duration={2} separator="," /> : "0"}
		</span>
	);
};

export default Counter;
