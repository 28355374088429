import Footer from "../components/common/Footer";
import DefaultHeader from "../components/common/DefaultHeader";
import HeaderSidebar from "../components/common/HeaderSidebar";
import HeaderTop from "../components/common/HeaderTop";
import MobileMenu from "../components/common/MobileMenu";
import LoginSignupModal from "../components/common/login-signup";

import AboutTextBlock from "../components/pages/about/AboutTextBlock";
import WhyChoose from "../components/common/WhyChoose";
//import Team from "@/app/components/common/Team";
//import Testimonial from "@/app/components/common/Testimonial";
//import Partner from "@/app/components/common/Partner";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

export const metadata = {
	title: "About Us || Voiture - Automotive & Car Dealer NextJS Template",
};

const AboutUs = () => {

	window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

	const { t } = useTranslation();

	<Helmet>
        <title>About || KAGA Motors - Automotive & Car Dealer in Japan</title>
        <meta name="description" content="About KAGA Motors - Automotive & Car Dealer in Japan." />
        <meta name="theme-color" content="#008f68" />
		<meta property="og:title" content={'About || KAGA Motors - Automotive & Car Dealer'} />
        <meta property="og:description" content={'About KAGA Motors - Automotive & Car Dealer in Japan.'} />
        <meta property="og:image" content={'https://kagamotors.jp/assets/images/logo.png'} />
        <meta property="og:url" content={'https://kagamotors.jp/about'} />
        <meta property="og:type" content="website" />
    </Helmet>
	
	return (
		<div className="wrapper">
			<div
				className="offcanvas offcanvas-end"
				tabIndex="-1"
				id="offcanvasRight"
				aria-labelledby="offcanvasRightLabel"
			>
				<HeaderSidebar t={t} />
			</div>
			{/* Sidebar Panel End */}

			{/* header top */}
			<HeaderTop t={t} />
			{/* End header top */}

			{/* Main Header Nav */}
			<DefaultHeader t={t} />
			{/* End Main Header Nav */}

			{/* Main Header Nav For Mobile */}
			<MobileMenu t={t} />
			{/* End Main Header Nav For Mobile */}

			{/* Inner Page Breadcrumb */}
			<section className="inner_page_breadcrumb style2 bgc-f9 bt1">
				<div className="container">
					<div className="row">
						<div className="col-xl-12">
							<div className="breadcrumb_content style2">
								<h2 className="breadcrumb_title">{t('header.about-us')}</h2>
								<p className="subtitle">{t('header.about-us')}</p>
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<a href="#">{t('header.home')}</a>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										<a href="#">{t('header.about-us')}</a>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Inner Page Breadcrumb */}

			{/* About Text Content */}
			<section className="about-section pb20">
				<div className="container">
					<div className="row">
						<AboutTextBlock t={t} />
					</div>
				</div>
			</section>
			{/* End About Text Content */}

			{/* About Text Content */}
			<section className="about-section pb130 pt0">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 offset-lg-1">
							<p>{t('about-us.about-us-desc-1')}</p>
							{/* <p>Kaga motors established in 2004 is now one of the leading used truck and bus dealer in whole of Japan,we are based in Tochigi and we have our branches in ibaraki and niigata, since 2020 we have the largest stock of used J bus and MS 9 tourist busses in Japan,    when you are dealing with kaga motors you will be guaranteed of a quality service with a service history of almost 20 years ,we have our own workshop , dismantling and vanning pits to facilitate your needs ,we are also capable of finding the best shipping solution for your vehicle  to reach any corner of the globe, our clientele includes buyers from all five continent' s and please fell free to contact our export section for your needs and we are waiting to serve you with a smile.</p> */}
						</div>
						<div className="col-lg-5 offset-lg-1">
							<p>{t('about-us.about-us-desc-2')}</p>
							{/* <p>Our stock is purchased by experienced personnel who are experts in finding all possible defects and fault 's of vehicles , and we try our best to show all faults and defects of the vehicle if any in the 80 odd pictures we show on our website so the customer will be 100 percent satisfied once the unit arrives at his destination</p> */}
						</div>
					</div>
				</div>
			</section>
			{/* End About Text Content */}

			{/* Images */}
			<section className="why-chose pb90 pt0-md">
				<div className="container">
					<div className="row">
						<div
							className="col-sm-6 col-lg-4"
							data-aos="fade-in"
							data-aos-delay={100}
							key={0}
						>
							<img src="../../assets/images/home/front1.jpg" />
							<div className="home7_style">
							</div>
						</div>
						<div
							className="col-sm-6 col-lg-4"
							data-aos="fade-in"
							data-aos-delay={200}
							key={0}
						>
							<img src="../../assets/images/home/front2.jpg" />
							<div className="home7_style">
							</div>
						</div>
						<div
							className="col-sm-6 col-lg-4"
							data-aos="fade-in"
							data-aos-delay={300}
							key={0}
						>
							<img src="../../assets/images/home/front3.jpg" />
							<div className="home7_style">
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End Images */}

			{/* Why Chose Us */}
			<section className="why-chose pb90 pt0-md">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="main-title text-center">
								<h2>{ t('why-choose.why-choose-us') }</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<WhyChoose t={t} />
					</div>
				</div>
			</section>
			{/* End Why Chose Us */}

			{/* Our Team */}
			{/* <section className="our-team pb90 pt0">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 offset-lg-3">
							<div className="main-title text-center">
								<h2>Our Team</h2>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-12">
							<div
								className="home1_popular_listing home2_style"
								data-aos="fade-up"
								data-aos-delay="100"
							>
								<div className="listing_item_4grid_slider dots_none">
									<Team />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* End Our Team */}

			{/* Testimonials  */}
			{/* <section className="our-testimonials-home1 pt120 pb120">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="main-title text-center">
								<h2>Testimonials</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="testimonial_slider_home1">
								<Testimonial />
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* End Testimonials  */}

			{/* Our Partners */}
			{/* <section className="our-partner pb100">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 offset-lg-3">
							<div className="main-title text-center">
								<h2>Popular Makes</h2>
							</div>
						</div>
					</div>

					<div className="partner_divider">
						<div className="row">
							<Partner />
						</div>
					</div>
				</div>
			</section> */}
			{/* End  Our Partners */}

			{/* Our Footer */}
			<Footer t={t} />
			{/* End Our Footer */}

			{/* Modal */}
			<div
				className="sign_up_modal modal fade"
				id="logInModal"
				data-backdrop="static"
				data-keyboard="false"
				tabIndex={-1}
				aria-hidden="true"
			>
				<LoginSignupModal />
			</div>
			{/* End Modal */}
		</div>
		// End wrapper
	);
};

export default AboutUs;
