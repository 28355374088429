import DashboardHeader from "../../components/common/dashboard/DashboardHeader";
import DashboardHeaderTop from "../../components/common/dashboard/DashboardHeaderTop";
import DashboardSidebarMenu from "../../components/common/dashboard/DashboardSidebarMenu";
import DashboardSidebarMobileMenu from "../../components/common/dashboard/DashboardSidebarMobileMenu";

import HeaderSidebar from "../../components/common/HeaderSidebar";
import MobileMenu from "../../components/common/MobileMenu";
import LoginSignupModal from "../../components/common/login-signup";

import DetailTable from "../../components/common/dashboard/container-detail/DetailTable";
import CostTable from "../../components/common/dashboard/order-detail/CostTable";
import PaymentTable from "../../components/common/dashboard/order-detail/PaymentTable";

import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import CrudService from '../../services/crudService'
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

export const metadata = {
    title: "My Listing || Voiture - Automotive & Car Dealer NextJS Template",
};

const ViewOrder = () => {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [orderData, setOrderData] = useState([]);
    //const [vendorData, setVendorData] = useState([]);
    const [detailList, setDetailList] = useState([]);
    const [costList, setCostList] = useState([]);
    const [paymentList, setPaymentList] = useState([]);

    const [id, setId] = useState(0);

    useEffect(() => {
        if (params.id) {
            setId(params.id);

            CrudService.GetDataById(navigate, location, 'container', params.id)
                .then((res) => {
                    setOrderData(res.data.order);
                    setDetailList(res.data.detail);
                    //setVendorData(res.data.vendor);
                    setCostList(res.data.cost);
                    //setPaymentList(res.data.payments);
                })
        }

    }, [id]);

    if (!params.id)
        navigate(location);


    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    //-------------------------
    var total_ = 0;
    var total_cost_ = 0;

    detailList?.map((item) => {
        total_ += parseFloat(item.SalesOrder.sub_total);
    });

    costList?.map((item) => {
        total_cost_ += parseFloat(item.amount);
    })

    return (
        <div className="wrapper">
            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <HeaderSidebar t={t} />
            </div>
            {/* Sidebar Panel End */}

            {/* header top */}
            <DashboardHeaderTop t={t} />
            {/* End header top */}

            {/* Main Header Nav */}
            <DashboardHeader t={t} />
            {/* End Main Header Nav */}

            {/* Main Header Nav For Mobile */}
            <MobileMenu t={t} />
            {/* End Main Header Nav For Mobile */}

            {/* Our Dashbord */}
            <section className="our-dashbord dashbord bgc-f9">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xxl-10 offset-xxl-2 dashboard_grid_space">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="extra-dashboard-menu dn-lg">
                                        <div className="ed_menu_list">
                                            <ul>
                                                <DashboardSidebarMenu t={t} />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End .row */}

                            <div className="dashboard_navigationbar dn db-lg mt50">
                                <DashboardSidebarMobileMenu t={t} />
                            </div>
                            {/* End mobile navigation */}

                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="breadcrumb_content mb50">
                                        <h2 className="breadcrumb_title">View Container</h2>
                                        <p>Ready to jump back in!</p>
                                    </div>
                                </div>
                            </div>
                            {/* End .row */}

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="new_property_form mb30">
                                        <div class="row">
                                            <div class="col-md-1">
                                                <p>#: {orderData.id}</p>
                                            </div>
                                            {/* <div class="col-xl-4 col-lg-4 col-md-3">
                                                <p>Currency: {(detailList.SalesOrder.currency == 1) ? 'JPY' : 'USD'}</p>
                                            </div> */}
                                            <div class="col-md-2">
                                                <p>Status: {(orderData.status == 1) && <span class="badge bg-primary">Preparing</span> || (orderData.status == 2) && <span class="badge bg-warning">In-Transit</span> || <span class="badge bg-success">Completed</span>} </p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Final Amount: <NumericFormat type="text" displayType="text" value={ total_ + total_cost_ } thousandsGroupStyle="thousand" thousandSeparator="," /> </p> 
                                            </div>
                                            <div class="col-md-3">
                                                <p>Discount: <NumericFormat type="text" displayType="text" value={ orderData.discount } thousandsGroupStyle="thousand" thousandSeparator="," /> </p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Paid: <NumericFormat type="text" displayType="text" value={ orderData.amount_paid } thousandsGroupStyle="thousand" thousandSeparator="," /> </p>
                                            </div>
                                        </div>
                                        {/* <div class="row">
                                            <div class="col-xl-4 col-lg-4 col-md-3">
                                                <p>Sub Total: <NumericFormat type="text" displayType="text" value={ (orderData.currency == 1) ? Number(orderData.sub_total) : orderData.sub_total } thousandsGroupStyle="thousand" thousandSeparator="," /></p>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-3">
                                                <p>Amount Paid: <NumericFormat type="text" displayType="text" value={ (orderData.currency == 1) ? Number(orderData.amount_paid) : orderData.amount_paid } thousandsGroupStyle="thousand" thousandSeparator="," /></p>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-3">
                                                <p>Amount Remaining: <NumericFormat type="text" displayType="text" value={ (orderData.currency == 1) ? Number(orderData.amount_remaining) : orderData.amount_remaining } thousandsGroupStyle="thousand" thousandSeparator="," /></p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End .row */}

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="new_property_form mb30">
                                        <DetailTable data={detailList} />
                                    </div>
                                </div>
                            </div>
                            {/* End .row */}

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="new_property_form mb30">
                                        <CostTable data={costList} />
                                    </div>
                                </div>
                            </div>
                            {/* End .row */}

                            
                        </div>
                    </div>
                </div>
            </section>
            {/* End Our Dashbord */}

            {/* Modal */}
            <div
                className="sign_up_modal modal fade"
                id="logInModal"
                data-backdrop="static"
                data-keyboard=""
                tabIndex={-1}
                aria-hidden="true"
            >
                <LoginSignupModal />
            </div>
            {/* End Modal */}
        </div>
        // End wrapper
    );
};

export default ViewOrder;
