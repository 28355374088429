const WhyChoose = ({t}) => {
	const reasons = [
		{
			iconClass: "flaticon-price-tag",
			title: t('why-choose.best-price'), //"Best Price",
			description: t('why-choose.best-price-desc'), //"We offer best price for Your Tailormade Vehicle Requirements. ",
			delay: 100,
			style: "style1",
		},
		{
			iconClass: "flaticon-car",
			title: t('why-choose.trusted-by'), //"Trusted By Thousands",
			description: t('why-choose.trusted-by-desc'), //"20+ Years of service trusted by thousands of Customers.",
			delay: 200,
			style: "style2",
		},
		{
			iconClass: "flaticon-trust",
			title: t('why-choose.wide-range-brands'), //"Wide Range of Brands",
			description: t('why-choose.wide-range-desc'), //"Wide Range of brands to select in excellent condition.",
			delay: 300,
			style: "style3",
		},
	];

	return (
		<>
			{reasons.map((reason, index) => (
				<div
					className="col-sm-6 col-lg-4"
					data-aos="fade-up"
					data-aos-delay={reason.delay}
					key={index}
				>
					<div className="why_chose_us home7_style">
						<div className={`icon ${reason.style}`}>
							<span className={reason.iconClass} />
						</div>
						<div className="details">
							<h5 className="title">{reason.title}</h5>
							<p>{reason.description}</p>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default WhyChoose;
