import { NavLink } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

const VehicleContent = ({ orderList }) => {
	return (
		<div className="col-lg-12">
			<div className="table-responsive my_lisging_table">
				<table className="table">
					<thead className="table-light">
						<tr className="thead_row">
							<th className="thead_title pl20" scope="col">
								Ref #
							</th>
                            <th className="thead_title" scope="col">
								Title
							</th>
							<th className="thead_title" scope="col">
								VIN
							</th>
                            <th className="thead_title" scope="col">
								Price
							</th>
                            <th className="thead_title" scope="col">
								Sell Price
							</th>
                            <th className="thead_title" scope="col">
								Order Date
							</th>
							<th className="thead_title" scope="col">
								Shipped
							</th>
							<th className="thead_title" scope="col">
								Action
							</th>
						</tr>
					</thead>
					{/* End thead */}

					<tbody>
						{orderList?.map((value) => {
							let utcDate = value.order_date;
							let localDate = new Date(utcDate);
							
							return (
								<tr key={value.id}>
									<td class="align-middle">
										<NavLink to={`/detail/${value.id}`} className={'text-primary'}>{ value.ref_no }</NavLink>
									</td>
                                    <td className="align-middle">{ value.title }</td>
									<td className="align-middle">{ value.vin }</td>
                                    <td className="align-middle">¥ <NumericFormat type="text" displayType="text" value={ Number(value.price) } thousandsGroupStyle="thousand" thousandSeparator="," /> </td>
                                    <td className="align-middle">{ (value.currency == 1) ? '¥' : '$' } <NumericFormat type="text" displayType="text" value={ Number(value.sell_price) } thousandsGroupStyle="thousand" thousandSeparator="," /> </td>
                                    <td className="align-middle">{ localDate.toLocaleDateString() }</td>
                                    {/* <td className="align-middle">{ (value.status == 1) && <span class="badge bg-primary">Preparing</span> || (value.status == 1) && <span class="badge bg-warning">In-Transit</span> || <span class="badge bg-success">Completed</span> }</td> */}
									<td className="align-middle">{ (value.shipped == 1) ? <span class="badge bg-success">Shipped</span> : <span class="badge bg-primary">Pending</span> }</td>
									
									
									<td className="editing_list align-middle">
										<ul>
											<li className="list-inline-item mb-1">
												<NavLink exact to={`/detail/${value.id}`} className={'text-primary'} title="View"><span className="flaticon-view"></span></NavLink>
											</li>
										</ul>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default VehicleContent;
