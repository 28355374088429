const BreadCrumb = ({t}) => {
	return (
		<ol className="breadcrumb float-start">
			<li className="breadcrumb-item">
				<a href="#">{ t('header.stock') }</a>
			</li>
			<li className="breadcrumb-item active" aria-current="page">
				Vehicle Detail
			</li>
		</ol>
	);
};

export default BreadCrumb;
