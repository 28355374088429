import React, { useState } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

const PriceRange = ({ handleOutFilter }) => {
    const [price, setPrice] = useState({ value: { min: 50000, max: 100000000 } });

    // price range handler
    const handleOnChange = (value) => {
        setPrice({ value });

        //handleOutFilter( 'minprice', value.min );
        //handleOutFilter( 'maxprice', value.max );

        handleOutFilter(null, null, value);

        console.log('minprice :'+ value.min);
		console.log('maxprice :'+ value.max);
    };

    return (
        <div>
            <InputRange
                formatLabel={() => ``}
                maxValue={2000000}
                minValue={0}
                value={price.value}
                onChange={(value) => handleOnChange(value)}
                id="slider"
            />
            <span id="slider-range-value1">¥{price.value.min}</span>
            <span id="slider-range-value2">¥{price.value.max}</span>
        </div>
    );
};

export default PriceRange;
